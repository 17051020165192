import * as React from 'react'
import { connect } from 'react-redux'
import { Grid } from 'semantic-ui-react'

import Graph from './Graph'
import Income from './Income'

import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Transactions/Actions'
import { ManagerNotes } from 'store/Transactions/Types'
import * as ProfileActions from 'store/UserProfile/Actions'
import {
  getManagerNote,
  getTransactionCommission,
  getTransactionCreditDebit,
  getTransactionDetails
} from '../../../Dashboard/TransactionQueries'
import { CommissionWrapper } from './Styled'

import {
  CommissionType,
  TransactionType,
  UserType,
} from 'app/Transactions/Details/Types'
import {
  CreditDebitType,
  TotalCommissions,
} from 'store/Transactions/Types'

interface StoreProps {
  setCommission: (data: Partial<CommissionType>) => void
  setUpdateCommission: (data: Partial<CommissionType>) => void
  setTotalCommission: (data: Partial<CommissionType>) => void
  setManagerNotes: (notes: ManagerNotes[]) => void
  setPaymentApprove: (approve: boolean) => void
  setPaymentReleased: (release: boolean) => void
  setTransaction: (data: TransactionType) => void
  setToggleNotesModal: (open: boolean) => void
  setUserCreditDebit: (data: CreditDebitType) => void
  setFeeAdjustmentFlag: (data: boolean) => void
  setHasTeamFlag: (data: boolean) => void
  creditDebit: CreditDebitType
  commission: CommissionType
  managerNotes: ManagerNotes[]
  totalCommissions: TotalCommissions
  isApproved: boolean
  isReleased: boolean
  toggleNotes: boolean
}

interface OwnProps {
  transaction: TransactionType
  user: UserType
}

type Props = OwnProps & StoreProps

class Commission extends React.Component<Props, {}> {

  public async componentDidMount() {
    const {
      setCommission,
      setManagerNotes,
      setTotalCommission,
      setUserCreditDebit,
      setFeeAdjustmentFlag,
      setHasTeamFlag,
      transaction,
    } = this.props

    const commission = await getTransactionCommission(transaction._id)
    setCommission(commission)

    const isResidential = transaction?.propertyId?.type?.name === 'Residential'
    const hasTeam = transaction?.owner?.hasTeam ?? false

    const setFlags = (completedCap: number, targetCap: number) => {
      setFeeAdjustmentFlag(completedCap >= targetCap)
    }

    if (transaction && transaction.propertyId && transaction.propertyId.type) {
      if (isResidential) {
        setHasTeamFlag(hasTeam)
        if (hasTeam) {
          setFlags(commission.teamCap.residentialCompletedCap, commission.teamCap.residentialTargetCap)
        } else {
          setFlags(commission.transaction.owner.userCap.residentialCompletedCap, commission.transaction.owner.userCap.residentialTargetCap)
        }
      } else {
        if (hasTeam) {
          setHasTeamFlag(true)
          setFlags(commission.teamCap.commercialCompletedCap, commission.teamCap.commercialTargetCap)
        } else {
          setFlags(commission.transaction.owner.userCap.commercialCompletedCap, commission.transaction.owner.userCap.commercialTargetCap)
        }
      }
    }

    let transactionFeeAmount = 0, commissionFeeAmount = 0
    let capMaxAmount = 0, capCompletedAmount = 0

    if (isResidential) {
      if (hasTeam) {
        capMaxAmount = commission.teamCap.residentialTargetCap
        capCompletedAmount = commission.teamCap.residentialCompletedCap
      } else {
        capMaxAmount = commission.transaction.owner.userCap.residentialTargetCap
        capCompletedAmount = commission.transaction.owner.userCap.residentialCompletedCap
      }
    } else {
      if (hasTeam) {
        capMaxAmount = commission.teamCap.commercialTargetCap
        capCompletedAmount = commission.teamCap.commercialCompletedCap
      } else {
        capMaxAmount = commission.transaction.owner.userCap.commercialTargetCap
        capCompletedAmount = commission.transaction.owner.userCap.commercialCompletedCap
      }
    }

    if (commission.fees) {
      const transactionFee = commission.fees.find((el: any) => el.name === 'Transaction Fee')
      if (transactionFee) {
        transactionFeeAmount = transactionFee.amount
      }
      const commissionFee = commission.fees.find((el: any) => el.name === 'Commission Fee (default)')
      if (commissionFee) {
        commissionFeeAmount = commissionFee.amount
      }
    }

    const exceedsCap = (transactionFeeAmount + commissionFeeAmount) > (capMaxAmount - capCompletedAmount)
    setFeeAdjustmentFlag(exceedsCap)


    const managerNotes = await getManagerNote(transaction._id)
    setManagerNotes(managerNotes)

    const creditDebit = await getTransactionCreditDebit(transaction.owner._id)
    const userCreditDebit = creditDebit.map((element: any) => {
      return {
        ...element,
        thumbsDown: false,
        thumbsUp: false
      }
    })
    setUserCreditDebit(userCreditDebit)

    const newData = {
      commissionDue: commission.commissionDue,
      totalCredits: commission.totalCredits,
      totalDebits: commission.totalDebits,
      totalExpenses: commission.totalExpenses,
      totalIncome: commission.totalIncome,
      totalReferrals: commission.totalReferrals
    }
    setTotalCommission(newData)
  }

  public render() {
    const {
      commission,
      isApproved,
      isReleased,
      managerNotes,
      setCommission,
      setUpdateCommission,
      toggleNotes,
      totalCommissions,
      transaction,
      user,
    } = this.props

    return (
      <CommissionWrapper>
        <Grid>
          <Grid.Row>
            <Grid.Column width={6}>
              <Graph
                approved={this.approved}
                closeModal={this.closeModal}
                commission={commission}
                isApproved={isApproved}
                isReleased={isReleased} 
                managerNotes={managerNotes}
                released={this.released}
                showManagerNotesModal={this.showManagerNotesModal}
                toggleNotes={toggleNotes}
                totalCommissions={totalCommissions}
                transaction={transaction}
                user={user}
              />
            </Grid.Column>
            <Grid.Column width={10}>
              {Object.keys(commission).length > 0 && (
                <Income
                  commission={commission}
                  getCommission={setCommission}
                  totalCommissions={totalCommissions}
                  transaction={transaction}
                  updateCommission={setUpdateCommission}
                  user={user}
                />
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </CommissionWrapper>
    )
  }

  public closeModal = () => {
    const { setToggleNotesModal } = this.props
    setToggleNotesModal(false)
  }

  public showManagerNotesModal = () => {
    const { setToggleNotesModal } = this.props
    setToggleNotesModal(true)
  }

  public approved = async (approve: boolean) => {
    const { setPaymentApprove, transaction, setTransaction } = this.props
    setPaymentApprove(approve)
    const details = await getTransactionDetails(transaction._id)
    setTransaction(details[0])
  }

  public released = async (release: boolean) => {
    const { setPaymentReleased, transaction, setTransaction } = this.props
    setPaymentReleased(release)
    const details = await getTransactionDetails(transaction._id)
    setTransaction(details[0])
  }
}

const mapStateToProps = (state: AppState) => ({
  commission: state.transactions.commission,
  creditDebit: state.transactions.creditDebit,
  isApproved: state.transactions.isApproved,
  isReleased: state.transactions.isReleased,
  managerNotes: state.transactions.managerNotes,
  toggleNotes: state.transactions.toggleNotesModal,
  totalCommissions: state.transactions.totalCommission,
})

export default connect(
  mapStateToProps,
  {
    setCommission: Actions.getCommission,
    setManagerNotes: Actions.getManagerNotes,
    setPaymentApprove: Actions.paymentApprove,
    setPaymentReleased: Actions.paymentReleased,
    setToggleNotesModal: Actions.toggleNotesModal,
    setTotalCommission: Actions.totalCommission,
    setTransaction: Actions.getTransactionDetail,
    setUpdateCommission: Actions.updateCommission,
    setUserCreditDebit: ProfileActions.getUserCreditDebit,
    setFeeAdjustmentFlag: Actions.setFeeAdjustmentFlag,
    setHasTeamFlag: Actions.setHasTeamFlag
  }
)(Commission)
