import * as React from 'react'
import { connect } from 'react-redux'
import ConfirmAlert from 'sweetalert2'

import Button from 'shared/Button'
import List from 'shared/List'
import Toast, { serverToast } from 'shared/Toast/Toast'
import ItemForm from './ItemForm'
import AddReferralFeeModal from '../../AddReferralFeeModal'

import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Transactions/Actions'
import * as ProfileActions from 'store/UserProfile/Actions'

import {
  createTransactionFee,
  createTransactionReferralFee,
  deleteTransactionFee,
  deleteTransactionReferralFee,
  updateTransactionFee,
  updateTransactionReferralFee,
} from 'app/Transactions/Dashboard/TransactionMutations'
import {
  getTransactionCommission,
  getTransactionCreditDebit
} from 'app/Transactions/Dashboard/TransactionQueries'

import {
  CommissionItemType,
  CommissionType,
  TransactionType,
  UserType,
} from 'app/Transactions/Details/Types'
import {
  Commission,
  FeeType,
  PaymentTypeEnum,
  ReferralType,
} from 'store/Transactions/Types'
import { PermissionEnum } from './Types'
import {
  ActionEnum as FormActionEnum,
  FormType,
  FormErrorType,
} from './ItemForm/Types'

import {
  Col,
  Dollar,
  Line,
  Link,
  Row,
} from './Styled'

import { faPencilAlt, faTrashAlt } from '@fortawesome/pro-light-svg-icons'
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
import { ReferralTypeEnum } from '../../AddReferralFeeModal/Types'
import { Strings } from 'utils'
import { StyledPopup } from 'app/Transactions/Details/SubPages/Commission/Income/Styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


AddIconToLibrary([
  faPencilAlt,
  faPlus,
  faTrashAlt,
])

interface StoreProps {
  getCommission: (data: Partial<CommissionType>) => void
  isApproved: boolean
  isReleased: boolean
  feeAdjustmentFlag: boolean
  hasTeamFlag: boolean
  setAddedFee: (newData: FeeType) => void
  setAddedReferralFee: (newData: FeeType) => void
  setDeleteFee: (id: string) => void
  setDeleteReferralFee: (id: string) => void
  setTotalCommission: (data: Commission) => void
  setUpdatedFee: (id: string, newDate: FeeType) => void
  setUpdateReferralFee: (id: string, newData: FeeType) => void
  setUserCreditDebit: (data: CommissionItemType) => void
  setFeeAdjustmentFlag: (data: boolean) => void
}

interface OwnProps {
  commission: CommissionType
  transaction: TransactionType
  type: PaymentTypeEnum
  user: UserType
}

type Props = OwnProps & StoreProps

interface State {
  activeItem: CommissionItemType
  escrowPayment: string
  form: FormType
  isEditing: PaymentTypeEnum
  permission: PermissionEnum
  source: string
  total: string
  isEdit: boolean
  loading: boolean
}

function filterCommissionItems(source: string, type: PaymentTypeEnum, commission: any): CommissionItemType[] {
  const items: CommissionItemType[] = commission[source] || []
  return items.filter((item: CommissionItemType) => {
    const isSpecificFeeWithZeroAmount = (item.amount === 0) &&
      (item.name === 'Transaction Fee' || item.name === 'Commission Fee (default)')

    return (type === PaymentTypeEnum.Referral || item.type === type) &&
      (item.amount !== 0 || isSpecificFeeWithZeroAmount)
  })
}

class LineItems extends React.Component<Props, State> {
  public state = {
    activeItem: {} as CommissionItemType,
    escrowPayment: '',
    isEditing: PaymentTypeEnum.None,
    form: {
      errors: {} as FormErrorType,
    } as FormType,
    permission: PermissionEnum.Viewer,
    source: 'referrals',
    total: 'totalReferrals',
    isEdit: false,
    loading: false
  }

  public async componentDidMount() {
    const { type, commission } = this.props
    let { source, total } = this.state

    switch (type) {
      case PaymentTypeEnum.Credit:
        total = 'totalCredits'
        source = 'fees'
        break

      case PaymentTypeEnum.Debit:
        total = 'totalDebits'
        source = 'fees'
        break

      case PaymentTypeEnum.Referral:
      default:
        total = 'totalReferrals'
        source = 'referrals'
        break
    }

    this.setState({
      escrowPayment: commission.referrals ? commission.referrals.find((referral: ReferralType) => referral.type === ReferralTypeEnum.Escrow)?._id || '' : '',
      permission: this.getPermission(),
      source,
      total,
    })
  }

  public render() {
    const {
      commission,
      transaction,
      type,
      feeAdjustmentFlag,
      hasTeamFlag
    } = this.props

    

    const {
      activeItem,
      isEditing,
      form,
      permission,
      source,
      total,
      isEdit,
      loading
    } = this.state

    const isReferral = type === PaymentTypeEnum.Referral
    // const lines:CommissionItemType[] = (commission[source] || [] as CommissionItemType[]).filter((item: CommissionItemType) => {
    //   return (type === PaymentTypeEnum.Referral || item.type === type)
    // })

    const lines: CommissionItemType[] = filterCommissionItems(source, type, commission)
  

    return (
      <React.Fragment>
        {isEditing === PaymentTypeEnum.Referral && (
          <AddReferralFeeModal
            inputData={activeItem}
            commission={commission}
            transaction={transaction}
            onAdd={this.addReferralFee}
            onClose={this.handleResetForm}
            onUpdate={this.updateReferralFee}
            isEdit={isEdit}
          />
        )}
        <List.Grouped
          hasItems={(isReferral && permission >= PermissionEnum.Editor) || permission >= PermissionEnum.Super || !!lines.length}
          header={(
            <Row childWidths={'30 70'}>
              <Col>Total {type}s</Col>
              <Col>
                <Dollar type={type} amount={commission[total]} />
              </Col>
            </Row>
          )}
          items={(
            <React.Fragment>
              {lines.map((item: CommissionItemType, index: number) => (
                <React.Fragment key={index}>
                  {!isReferral && activeItem._id === item._id && !isEditing ? (
                    <Line childWidths={'40 20 20 20'}>
                      <ItemForm
                        form={form}
                        item={item}
                        onAction={this.handleFormAction}
                        isEditing={true}
                        loading={loading}
                      />
                    </Line>
                  ) : (
                    <Line childWidths={'30 70'}>
                      <Col>{item.name || item.type || ''}</Col>
                      <Col justify='space-between'>
                        <Col primary={true}>
                          <Dollar type={item.type} amount={item.amount} />
                          {feeAdjustmentFlag && (item.name === 'Transaction Fee' || item.name === 'Commission Fee (default)') && (
                          hasTeamFlag ? <StyledPopup
                            position="bottom center"
                           className="error-popup"
                            trigger={<FontAwesomeIcon color='#dc3912' icon={['fal', 'info-circle']} />}
                            content={Strings.dashboard.commission.individualCapAdjustmentInfoError}
                          />
                            : <StyledPopup
                              position="bottom center"
                              className="error-popup"
                              trigger={<FontAwesomeIcon color='#dc3912' icon={['fal', 'info-circle']} />}
                              content={Strings.dashboard.commission.teamCapAdjustmentInfoError}
                            />
                        )}
                        </Col>
                      
                        <Col>
                          {(permission >= PermissionEnum.Editor) && (
                            <Button.Icon
                              icon={faPencilAlt}
                              label='Edit'
                              onClick={() => this.handleEdit(item)}
                            />
                          )}
                          {(permission >= PermissionEnum.Super) && (
                            <Button.Icon
                              icon={faTrashAlt}
                              label='Delete'
                              onClick={() => this.handleDelete(item._id)}
                            />
                          )}
                        </Col>
                      </Col>
                    </Line>
                  )}
                </React.Fragment>
              ))}
              {!isReferral && isEditing && (
                <Line childWidths={'40 20 20 20'}>
                  <ItemForm
                    form={form}
                    item={{} as CommissionItemType}
                    onAction={this.handleFormAction}
                    loading={loading}
                  />
                </Line>
              )}
              {(permission >= PermissionEnum.Editor) && (
                <Line>
                  <Button.Bubble
                    icon={faPlus}
                    onClick={this.toggleNew}
                  />
                  <Link onClick={this.toggleNew}>Add {type}</Link>
                </Line>
              )}
            </React.Fragment>
          )}
        />
      </React.Fragment>
    )
  }

  private handleFormAction = (action: FormActionEnum, data: any) => {
    switch (action) {
      case FormActionEnum.Change:
        this.handleChange(null, data)
        break

      case FormActionEnum.Submit:
        if (data) {
          this.updateFee()
        } else {
          this.addFee()
        }
        break

      case FormActionEnum.Cancel:
        this.handleResetForm()
        break

      default:
        break
    }
  }

  private handleChange = (e: React.SyntheticEvent<HTMLDivElement> | any, { name, value }: any) => {
    const { form } = this.state
    form[name] = value
    this.setState({ form })
  }

  private handleEdit = (item: CommissionItemType) => {
    const { type} = this.props

    const form = {
      errors: {} as FormErrorType,
      fee: item.feeObj.fee,
      name: item.name,
      type: item.feeObj.type,
    }
    this.setState({
      form,
      activeItem: item,
      isEditing: type === PaymentTypeEnum.Referral ? type : PaymentTypeEnum.None,
      isEdit: true
    })
  }

  private addFee = async (data?: string) => {
    const { setAddedFee, commission, type, setFeeAdjustmentFlag, transaction, hasTeamFlag } = this.props
    const { form } = this.state

    const input: any = {
      feeObj: {
        fee: form.fee,
        type: form.type,
      },
      name: form.name,
      payment: commission._id,
      type,
    }

    let transactionFeeAmount = 0, commissionFeeAmount = 0
    let capMaxAmount = 0, capCompletedAmount = 0

    const isResidential = transaction?.propertyId?.type?.name === 'Residential'

    if (isResidential) {
      if (hasTeamFlag) {
        capMaxAmount = commission.teamCap.residentialTargetCap
        capCompletedAmount = commission.teamCap.residentialCompletedCap
      } else {
        capMaxAmount = commission.transaction.owner.userCap.residentialTargetCap
        capCompletedAmount = commission.transaction.owner.userCap.residentialCompletedCap
      }
    } else {
      if (hasTeamFlag) {
        capMaxAmount = commission.teamCap.commercialTargetCap
        capCompletedAmount = commission.teamCap.commercialCompletedCap
      } else {
        capMaxAmount = commission.transaction.owner.userCap.commercialTargetCap
        capCompletedAmount = commission.transaction.owner.userCap.commercialCompletedCap
      }
    }

    const fees = commission.fees || []

    if (input.name === 'Transaction Fee') {
      transactionFeeAmount = +input.feeObj.fee
      const commissionFee = fees.find(el => el.name === 'Commission Fee (default)')
      if (commissionFee) {
        commissionFeeAmount = commissionFee.amount
      }
    } else if (input.name === 'Commission Fee (default)') {
      commissionFeeAmount = +input.feeObj.fee
      const transactionFee = fees.find(el => el.name === 'Transaction Fee')
      if (transactionFee) {
        transactionFeeAmount = transactionFee.amount
      }
    }


    const exceedsCap = (transactionFeeAmount + commissionFeeAmount) > (capMaxAmount - capCompletedAmount)
    setFeeAdjustmentFlag(exceedsCap)


    try {
      this.setState({ ...this.state, loading: true })
      const response = await createTransactionFee(input)
      this.updateCommission(response.payment)
      setAddedFee(response)
      await this.updateEscrow()
    } catch (error) {
      serverToast(error)
    } finally {
      this.setState({ ...this.state, loading: false })
    }

    this.handleResetForm()
  }

  private addReferralFee = async (data: any) => {
    const { setAddedReferralFee, commission } = this.props
    try {
      const response = await createTransactionReferralFee(commission._id, data)
      this.updateCommission(response.payment)
      setAddedReferralFee(response)
      await this.updateEscrow()
    } catch (error) {
      serverToast(error)
    }

    this.handleResetForm()
  }

  private updateFee = async () => {

    const { setUpdatedFee, commission, transaction, hasTeamFlag, setFeeAdjustmentFlag } = this.props
    const { activeItem, form } = this.state

    const input: any = {
      name: form.name,
      feeObj: {
        type: form.type,
        fee: form.fee,
      }
    }

    let transactionFeeAmount = 0, commissionFeeAmount = 0
    let capMaxAmount = 0, capCompletedAmount = 0

    const isResidential = transaction?.propertyId?.type?.name === 'Residential'

    if (isResidential) {
      if (hasTeamFlag) {
        capMaxAmount = commission.teamCap.residentialTargetCap
        capCompletedAmount = commission.teamCap.residentialCompletedCap
      } else {
        capMaxAmount = commission.transaction.owner.userCap.residentialTargetCap
        capCompletedAmount = commission.transaction.owner.userCap.residentialCompletedCap
      }
    } else {
      if (hasTeamFlag) {
        capMaxAmount = commission.teamCap.commercialTargetCap
        capCompletedAmount = commission.teamCap.commercialCompletedCap
      } else {
        capMaxAmount = commission.transaction.owner.userCap.commercialTargetCap
        capCompletedAmount = commission.transaction.owner.userCap.commercialCompletedCap
      }
    }

    const fees = commission.fees || []

    if (input.name === 'Transaction Fee') {
      transactionFeeAmount = +input.feeObj.fee
      const commissionFee = fees.find(el => el.name === 'Commission Fee (default)')
      if (commissionFee) {
        commissionFeeAmount = commissionFee.amount
      }
    } else if (input.name === 'Commission Fee (default)') {
      commissionFeeAmount = +input.feeObj.fee
      const transactionFee = fees.find(el => el.name === 'Transaction Fee')
      if (transactionFee) {
        transactionFeeAmount = transactionFee.amount
      }
    }


    const exceedsCap = (transactionFeeAmount + commissionFeeAmount) > (capMaxAmount - capCompletedAmount)
    setFeeAdjustmentFlag(exceedsCap)



    try {
      this.setState({ ...this.state, loading: true })
      const response: any = await updateTransactionFee(activeItem._id, input)
      this.updateCommission(response.payment)
      setUpdatedFee(activeItem._id, response)
      await this.updateEscrow()
    } catch (error) {
      serverToast(error)
    } finally {
      this.setState({ ...this.state, loading: false })
    }

    this.handleResetForm()
  }

  private updateReferralFee = async (id: string, data: any) => {
    const { setUpdateReferralFee } = this.props
    try {
      const response = await updateTransactionReferralFee(id, data)
      this.updateCommission(response.payment)
      setUpdateReferralFee(id, response)
      await this.updateEscrow()
    } catch (error) {
      serverToast(error)
    }

    this.handleResetForm()
  }

  private handleDelete = async (id: string) => {
    const {
      setDeleteFee,
      setDeleteReferralFee,
      setUserCreditDebit,
      transaction,
      type,
    } = this.props

    const confirm = await ConfirmAlert({
      cancelButtonText: 'No, keep it',
      confirmButtonText: 'Yes, delete it!',
      showCancelButton: true,
      text: 'You will not be able to recover this data!',
      title: 'Are you sure?',
      type: 'warning',
    })

    if (!confirm.value || confirm.dismiss) {
      Toast({
        message: 'No changes made',
        type: 'warning',
      })
      return
    }

    try {
      if (type === PaymentTypeEnum.Referral) {
        setDeleteReferralFee(id)
        await deleteTransactionReferralFee(id)
      } else {
        await deleteTransactionFee(id)
        setDeleteFee(id)
        const creditDebit = await getTransactionCreditDebit(transaction.owner._id)
        const updateCreditDebit = creditDebit.map((item: any) => {
          return {
            ...item,
            thumbsDown: false,
            thumbsUp: false
          }
        })
        setUserCreditDebit(updateCreditDebit)
      }

      await this.updateEscrow()

    } catch (error) {
      serverToast(error)
      return
    }

    try {
      const result = await getTransactionCommission(transaction._id)
      await this.updateCommission(result)
      await this.updateEscrow()
    } catch (error) {
      serverToast(error)
    }


    Toast({
      message: `${type} successfully deleted`,
      type: 'success',
    })
  }

  private updateCommission = async (commission: CommissionType) => {
    const { setTotalCommission } = this.props
    const update = {
      commissionDue: commission.commissionDue,
      totalCredits: commission.totalCredits,
      totalDebits: commission.totalDebits,
      totalExpenses: commission.totalExpenses,
      totalIncome: commission.totalIncome,
      totalReferrals: commission.totalReferrals,
    }
    setTotalCommission(update)
  }

  private toggleNew = () => {
    const { type } = this.props
    const { isEditing } = this.state


    this.setState({
      isEditing: isEditing ? PaymentTypeEnum.None : type,
      isEdit: false,
      form: {
        errors: {},
      }
    })
  }

  private async updateEscrow() {
    const { escrowPayment } = this.state
    const { transaction, getCommission } = this.props
    if (!escrowPayment) {
      return
    }
    try {
      const commission = await getTransactionCommission(transaction._id)
      getCommission(commission)
    } catch (error) {
      serverToast(error)
    }
  }

  private handleResetForm = () => {
    this.setState({
      activeItem: {} as CommissionItemType,
      isEditing: PaymentTypeEnum.None,
      form: {
        errors: {} as FormErrorType
      } as FormType,
      isEdit: false
    })
  }

  private getPermission = () => {
    const { isApproved, isReleased, user, transaction, type } = this.props
    let permission = PermissionEnum.None

    if (isApproved && isReleased) {
      return PermissionEnum.Viewer
    }

    if (type === PaymentTypeEnum.Referral && user._id === transaction.owner._id) {
      permission = PermissionEnum.Editor
    }

    switch (user.role) {
      case 'ADMIN':
      case 'MANAGER':
        return PermissionEnum.Super

      default:
        return permission
    }
  }
}

const mapStateToProps = (state: AppState) => ({
  isApproved: state.transactions.isApproved,
  isReleased: state.transactions.isReleased,
  feeAdjustmentFlag: state.transactions.feeAdjustmentFlag,
  hasTeamFlag: state.transactions.hasTeamFlag
})

export default connect(
  mapStateToProps,
  {
    getCommission: Actions.getCommission,
    setAddedFee: Actions.addFee,
    setAddedReferralFee: Actions.addReferralFee,
    setDeleteFee: Actions.deleteFeeDate,
    setDeleteReferralFee: Actions.deleteReferralFee,
    setTotalCommission: Actions.totalCommission,
    setUpdatedFee: Actions.editFeeDate,
    setUpdateReferralFee: Actions.editReferralFee,
    setUserCreditDebit: ProfileActions.getUserCreditDebit,
    setFeeAdjustmentFlag: Actions.setFeeAdjustmentFlag,
  }
)(LineItems)
