import { Popup, Table } from 'semantic-ui-react'
import styled from 'styled-components'

import Colors from 'design/Colors'
import { fonts } from 'design/Fonts'
import { y } from 'design/Styled'

export const IncomeWrapper = styled.div`
  width: 100%;
  overflow: auto;
  padding-right: 35px;
`

export const Container = styled.section`
  ${y};
  font: ${fonts.xSmall.regular};
  color: ${Colors.Text};
`

export const StyledTable = styled(Table)`

  .edit {
    height: 25px;
    width: 25px;
    margin-right: 10px;
    border-radius: 50%;
    background: ${Colors.DarkBlue200};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-left: 10px;

    .fa-check {
      color: ${Colors.White1000} !important;
      width: 12px;
      height: 12px;
      margin-right: 0;
      cursor: pointer;
    }
  }

  .download {
    user-select: none;
    cursor: pointer !important;
    text-align: center !important;

    .fa-file-invoice-dollar {
      margin-right: 8px;
      transform: scale(1.3);
    }

    .fa-info-circle{
      width: 14px;
      height: 11px;
      margin-left: 1px;
    }
  }

  .amount {
    height: 28px;
    width: 19%;
    margin-right: 10px;
  }

  .date {
    width: 29%;
    margin-right: 10px;
  }

  .ui.fluid.dropdown {
    font-size: 10px;
    margin-left: 10px;
    border-radius: 0;
    width: 20%;
  }

  .ui.input>input {
    border-radius: 0;
    font-size: 10px;
  }

  .make {
    font-weight: 400 !important;
    color: ${Colors.Black500} !important;
  }

  .svg-inline--fa {
    width: 14px;
    height: 14px;
  }

  .fa-pencil-alt {
    color: ${Colors.DarkBlue200} !important;
    width: 12px;
    height: 12px;
    margin-right: 6px;
    cursor: pointer;
  }

  .fa-trash-alt {
    color: ${Colors.DarkBlue200};
    cursor: pointer;
  }

  .fa-exclamation-circle {
    margin-right: 5px;
  }

  .fa-thumbs-up {
    margin-right: 8px;
  }

  .fa-user {
    margin-right: 5px;
  }

  &.ui.table {
    font-size: 12px;
    border-radius: 0;
    border-color: ${Colors.DarkBlue276};
    color: ${Colors.Black500};
  }

  &.ui.table thead th {
    background: ${Colors.DarkBlue276};
    color: ${Colors.DarkBlue200};
  }

  .fa-hand-holding-usd {
    color: ${Colors.DarkBlue200};
    margin-right: 6px;
    width: 16px;
    height: 16px;
  }

  .fa-exclamation-circle {
    color: ${Colors.Black500};
    margin-left: 6px;
  }

  .ui.small.image {
    width: 14px;
    height: 14px;
    margin-right: 6px;
  }

  .ui.selection.dropdown {
    border-radius: 0;
    min-height: 2.314286em;
    line-height: 0.8em;
    font-size: 10px;
  }
`

export const Thumbs = styled.div`
  display: flex;
  align-items: center;

  i.icon {
    font-size: 1.3em;
    margin-right: 10px;
  }
`

export const Edit = styled.div`
  display: flex;

  div:nth-child(1) {
    flex-grow: 1;
  }
`

export const StyledPopup = styled(Popup)`
  &.ui.popup {
    font-size: 0.8rem;
    width: 225px;
    color: ${Colors.Black500};
     &.error-popup{
     border-color: #dc3912!important;
    .content{
     color: #dc3912 !important;
    }
}
  }
`

export const FinancialTable = styled.section`
  ${y};
  font-size: 12px;
  border: 1px solid ${Colors.TableLine};
  border-bottom: none;
`

export const FinancialSummaryCommissionTableCell = styled(Table.Cell)`
  font-weight: 600;
  color: ${Colors.DarkBlue200};
  font-style: italic;
  display: flex;
  align-items: center;
`