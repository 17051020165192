import { debounce, mergeWith, omit } from 'lodash'
import client from 'queries/apollo'
import { cacheData } from 'queries/apollo'
import { UPDATE_CAP_SETTING } from 'queries/graphql/Settings/TransactionFee/CapSettings/Mutation'
import { GET_CAP_SETTING } from 'queries/graphql/Settings/TransactionFee/CapSettings/Queries'
import { getCapSettings } from 'queries/schema/schemaTypes'
import Toast from 'shared/Toast'

function undefinedChecker(objValue: any, srcValue: any) {
  return srcValue === null ? objValue : undefined
}

const changeRemoteData = debounce(async () => {
  const cacheStoreData = cacheData.readQuery({
    query: GET_CAP_SETTING,
  }) as getCapSettings
 
  try {
    await client.mutate({
      mutation: UPDATE_CAP_SETTING,
      variables: {
        _id: cacheStoreData.getCapSettings[0]._id,
        ...omit(cacheStoreData.getCapSettings[0], '_id'),
      },
    })
  } catch (error) {
    const errorMessage = error.message.replace(/^GraphQL error: /, '')
    Toast({message: errorMessage,
           type:'error'})
  }
}, 800)

export const updateCapSettingLocals = (obj: any, { data }: { data: any }, { cache }: { cache: any }) => {
  const cacheStoreData = cache.readQuery({
    query: GET_CAP_SETTING
  }) as getCapSettings
  const newData = mergeWith({}, cacheStoreData.getCapSettings[0], data, undefinedChecker)
  cache.writeQuery({
    data: { updateCapSetting: newData },
    query: UPDATE_CAP_SETTING
  })
  changeRemoteData()
  return newData
}

export default updateCapSettingLocals
