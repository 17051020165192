import { Reducer } from 'redux'
import Actions from 'store/Actions'
import { generateID } from 'utils'
import {
  addOtherFeeItem,
  addProgressiveFeeItem,
  changeOtherFeeItem,
  changeProgressiveFeeItem,
  deleteOtherFeeItem,
  deleteProgressiveFeeItem
} from './ReduxUtil'
import { TransactionFeeState } from './Types'

const initialState: TransactionFeeState = {
  capSettings: {
    additionalCap: 50000,
    commercialCap: 50000,
    enabled: true,
    exclusionCap: 50000,
    exclusionMax: 10000000,
    exclusionMin: 0,
    max: 100000,
    min: 0,
    residentialCap: 50000,
    teamCap: 50000
  },
  commissionFee: {
    amount: {
      percent: 0,
      range: [],
      value: 400
    },
    title: 'Commission Fee',
    type: 'FLAT'
  },
  expectedCommission: 9000,
  otherFee: {
    data: [
      {
        id: generateID(),
        name: 'Lorem Ipsum 1',
        type: 'FIXED',
        value: 600
      },
      {
        id: generateID(),
        name: 'Lorem Ipsum percentage',
        type: 'PERCENTAGE',
        value: 10
      }
    ],
    title: 'Other Fees'
  },
  principalPropertyCost: 300000,
  propertyFee: {
    commercialFee: {
      additional: 500,
      additionalMillionCap: 700000,
      base: 1000,
      baseMillionCap: 600000,
      title: 'Commercial Fee'
    },
    residentialFee: {
      additional: 600,
      additionalMillionCap: 400000,
      base: 1000,
      baseMillionCap: 900000,
      title: 'Residential Fee'
    },
    title: 'Property Fee'
  },
  toggleCapStatus : true

}

const transactionFeeReducer: Reducer<TransactionFeeState> = (
  state: TransactionFeeState = initialState,
  action
) => {
  switch (action.type) {
    case Actions.changeOtherFeeItem:
      return {
        ...state,
        otherFee: changeOtherFeeItem(
          state,
          action.payload.id,
          action.payload.newData
        )
      }
    case Actions.addOtherFeeItem:
      return {
        ...state,
        otherFee: addOtherFeeItem(state)
      }
    case Actions.deleteOtherFeeItem:
      return {
        ...state,
        otherFee: deleteOtherFeeItem(state, action.payload.id)
      }
    case Actions.changeCommissionFee:
      return {
        ...state,
        commissionFee: {
          ...state.commissionFee,
          amount: action.payload.newAmount
        }
      }
    case Actions.changeCommissionType:
      return {
        ...state,
        commissionFee: {
          ...state.commissionFee,
          amount: action.payload.newAmount,
          type: action.payload.type
        }
      }
    case Actions.changeResidentialFeeProperty:
      return {
        ...state,
        propertyFee: {
          ...state.propertyFee,
          residentialFee: {
            ...state.propertyFee.residentialFee,
            ...action.payload.newData
          }
        }
      }
    case Actions.changeCommercialFeeProperty:
      return {
        ...state,
        propertyFee: {
          ...state.propertyFee,
          commercialFee: {
            ...state.propertyFee.commercialFee,
            ...action.payload.newData
          }
        }
      }
    case Actions.changeProgressiveCommissionItem:
      return {
        ...state,
        commissionFee: changeProgressiveFeeItem(
          state,
          action.payload.id,
          action.payload.newData
        )
      }
    case Actions.addProgressiveCommissionItem:
      return {
        ...state,
        commissionFee: addProgressiveFeeItem(state)
      }
    case Actions.deleteProgressiveCommissionItem:
      return {
        ...state,
        commissionFee: deleteProgressiveFeeItem(state, action.payload.id)
      }
    case Actions.changeExpectedCommission:
      return {
        ...state,
        expectedCommission: action.payload.newValue
      }
    case Actions.changePrincipalPropertyCost:
      return {
        ...state,
        principalPropertyCost: action.payload.newValue
      }
    case Actions.changeResidentialCap:
      return {
        ...state,
        capSettings: {
          ...state.capSettings,
          residentialCap: action.payload.newValue
        }
      }
    case Actions.changeCommercialCap:
      return {
        ...state,
        capSettings: {
          ...state.capSettings,
          commercialCap: action.payload.newValue
        }
      }
    case Actions.changeTeamCap:
      return {
        ...state,
        capSettings: {
          ...state.capSettings,
          teamCap: action.payload.newValue
        }
      }
    case Actions.changeAdditionalCap:
      return {
        ...state,
        capSettings: {
          ...state.capSettings,
          additionalCap: action.payload.newValue
        }
      }
    case Actions.changeExclusionCap:
      return {
        ...state,
        capSettings: {
          ...state.capSettings,
          exclusionCap: action.payload.newValue
        }
      }
    case Actions.toggleCaps:
      return {
        ...state,
        capSettings: {
          ...state.capSettings,
          enabled: !state.capSettings.enabled
        }
      }
    case Actions.changeCapToggle:
      return{
        ...state,
        toggleCapStatus : action.payload.toggleCapStatus
      }
    default:
      return state
  }
}

export default transactionFeeReducer
