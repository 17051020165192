import gql from 'graphql-tag'

export const UPDATE_CAP_SETTING_LOCAL = gql`
  mutation updateCapSettingLocal(
    $_id: String
    $capStatus: String
    $individualResidentialCap: Int
    $individualCommercialCap: Int
    $teamResidentialCap: Int
    $teamCommercialCap: Int
    $additionalMemberCap: Int
  ) {
    updateCapSetting(
      data: {
        _id: $_id
        capStatus: $capStatus
        individualResidentialCap: $individualResidentialCap
        individualCommercialCap: $individualCommercialCap
        teamResidentialCap: $teamResidentialCap
        teamCommercialCap: $teamCommercialCap
        additionalMemberCap: $additionalMemberCap
      }
    ) @client {
      _id
      capStatus
      individualResidentialCap
      individualCommercialCap
      teamResidentialCap
      teamCommercialCap
      teamCap
      additionalMemberCap
    }
  }
`
