import moment from 'moment'
import * as React from 'react'
import { Dropdown, Input, Table } from 'semantic-ui-react'

import { getCapSettings } from 'app/Settings/TransactionFee/TransactionFeeDetails/TransactionFeeQueries'
import DatePicker from 'shared/DatePicker'
import { getTransactionsSetting } from 'app/Settings/TransactionFee/TransactionFeeDetails/TransactionFeeQueries'
import {
  updateUserCommissionPlan,
  updateUserLicensedExpiration,
  updateUserPaymentMethod
} from 'app/Transactions/Dashboard/TransactionMutations'

import Colors from 'design/Colors'
import { Strings } from 'utils'

import {
  TransactionType,
  UserType,
} from 'app/Transactions/Details/Types'
import {
  Commission,
  Options,
} from 'store/Transactions/Types'

import { StyledTable } from './Styled'

import { faUser } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faUser])

interface Props {
  commission: Commission
  transaction: TransactionType
  user: UserType
}

interface State {
  expiryData: string
  openDatePicker: boolean
  selectedCommissionPlan: Options[]
  paymentMethod: string
  commissionPlan: string
  selectedOption: Options
  capStatus: string
}

const paymentMethodOptions = [
  { key: 'Direct Deposit', value: 'Direct Deposit', text: 'Direct Deposit' },
  { key: 'ACH', value: 'ACH', text: 'ACH' },
  { key: 'Check', value: 'Check', text: 'Check' },
  { key: 'PayPal', value: 'PayPal', text: 'PayPal' }
]

class Transaction extends React.Component<Props, State> {
  public state = {
    capStatus: '',
    commissionPlan: '',
    expiryData: moment(new Date())
      .utc()
      .format('L'),
    openDatePicker: false,
    paymentMethod: '',
    selectedCommissionPlan: [],
    selectedOption: {} as Options
  }

  public async componentDidMount() {
    const { commission } = this.props
    const res = await getTransactionsSetting()
    const capStatus = await getCapSettings()
    const defaultSettings = res.find((element: any) => {
      return commission.transaction.owner.transactionFeeSetting.name === element.name
    })
    this.setState({
      selectedOption: {
        key: defaultSettings._id,
        text: defaultSettings.name,
        value: `${defaultSettings.name}, ${defaultSettings._id}`
      }
    })
    this.setState({
      commissionPlan: commission.transaction.owner.transactionFeeSetting.name,
      expiryData: commission.transaction.owner.licensedExpiration,
      paymentMethod: commission.transaction.owner.paymentMethod
    })
    if (res) {
      const selectedCommissionPlan: Options[] = []
      res.forEach((element: any) => {
        const obj: Options = {
          key: element._id,
          text: element.name,
          value: `${element.name}, ${element._id}`
        }
        selectedCommissionPlan.push(obj)
      })
      this.setState({ selectedCommissionPlan, capStatus })
    }
  }

  public render() {
    const { commission, transaction, user } = this.props
    const { expiryData, openDatePicker, selectedCommissionPlan, paymentMethod, capStatus } = this.state
    return (
      <StyledTable singleLine={true}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="2" style={{ whiteSpace: 'normal', overflowWrap: 'break-word' }}>
              <FontAwesomeIcon icon={['far', 'user']} />
              {commission.transaction.owner.firstName} {commission.transaction.owner.lastName}{' '}
              {Strings.dashboard.commission.transaction}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell width={10}>{Strings.dashboard.commission.licenseExp}</Table.Cell>
            <Table.Cell
              width={6}
              style={{ fontWeight: 600, color: Colors.DarkBlue200, fontStyle: 'italic', cursor: 'pointer' }}
            >
              {user.role === 'ADMIN' || user.role === 'MANAGER' ? (
                <>
                  <Input
                    className="date"
                    size="mini"
                    placeholder="Licence Expiration Date"
                    name="expiryData"
                    value={expiryData === 'Invalid date' || expiryData === null ? '' : moment(expiryData).format('L')}
                    onClick={this.handleOpen}
                    style={{ width: '50%' }}
                    autoComplete="off"
                  />
                  <DatePicker
                    onChangeDate={this.onChangeDate}
                    open={openDatePicker}
                    handleOpen={this.handleOpen}
                    handleClose={this.handleClose}
                    minDate={true}
                  />
                </>
              ) : (
                <span>
                  {expiryData === 'Invalid date' || expiryData === null ? '-' : moment(expiryData).format('L')}
                </span>
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={10}>{Strings.dashboard.commission.commissionPlan}</Table.Cell>
            <Table.Cell width={6} style={{ fontWeight: 600, color: Colors.DarkBlue200, fontStyle: 'italic' }}>
              <Dropdown
                upward={true}
                name="commissionPlan"
                placeholder={'Default'}
                selection={true}
                value={this.state.selectedOption[`value`]}
                options={selectedCommissionPlan}
                onChange={this.handleChange}
              />
            </Table.Cell>
          </Table.Row>
          {capStatus === 'ON' && (
            <>
            <Table.Row>
              <Table.Cell width={10}>{Strings.dashboard.commission.residentialCapContribution} ({moment(commission.expirationDate).format('MM/DD/YYYY')})</Table.Cell>
              {transaction.propertyId &&
                transaction.propertyId.type.name === 'Residential' ? (
                  commission.transaction.owner.hasTeam ? 
                  <Table.Cell width={6} style={{ fontWeight: 600, color: Colors.DarkBlue200, fontStyle: 'italic' }}>
                    {Number(commission.teamCap?.residentialCompletedCap).toFixed(2)}/
                    {Number(commission.teamCap?.residentialTargetCap).toFixed(2)}
                  </Table.Cell>
                  :
                  <Table.Cell width={6} style={{ fontWeight: 600, color: Colors.DarkBlue200, fontStyle: 'italic' }}>
                    {Number(commission.transaction.owner.userCap.residentialCompletedCap).toFixed(2)}/
                    {Number(commission.transaction.owner.userCap.residentialTargetCap).toFixed(2)}
                  </Table.Cell>
                ) : 
                <Table.Cell width={6} style={{ fontWeight: 600, color: Colors.DarkBlue200, fontStyle: 'italic' }}>
                    -
                </Table.Cell>}
              {/* {transaction.propertyId &&
                transaction.propertyId.type.name !== 'Commercial' &&
                transaction.propertyId.type.name !== 'Residential' && (
                  <Table.Cell width={6} style={{ fontWeight: 600, color: Colors.DarkBlue200, fontStyle: 'italic' }}>
                    -
                  </Table.Cell>
                )} */}
            </Table.Row>
            <Table.Row>
              <Table.Cell width={10}>{Strings.dashboard.commission.commercialCapContribution} ({moment(commission.expirationDate).format('MM/DD/YYYY')})</Table.Cell>
              {transaction.propertyId &&
                transaction.propertyId.type.name === 'Commercial' ? (
                  commission.transaction.owner.hasTeam ? 
                  <Table.Cell width={6} style={{ fontWeight: 600, color: Colors.DarkBlue200, fontStyle: 'italic' }}>
                    {Number(commission.teamCap?.commercialCompletedCap).toFixed(2)}/
                    {Number(commission.teamCap?.commercialTargetCap).toFixed(2)}
                  </Table.Cell>
                  :
                  <Table.Cell width={6} style={{ fontWeight: 600, color: Colors.DarkBlue200, fontStyle: 'italic' }}>
                    {Number(commission.transaction.owner.userCap.commercialCompletedCap).toFixed(2)}/
                    {Number(commission.transaction.owner.userCap.commercialTargetCap).toFixed(2)}
                  </Table.Cell>
                ) : 
                  <Table.Cell width={6} style={{ fontWeight: 600, color: Colors.DarkBlue200, fontStyle: 'italic' }}>
                    -
                  </Table.Cell>}
            </Table.Row>
            </>
          )}
          <Table.Row>
            <Table.Cell width={10}>{Strings.dashboard.commission.paymentMethod}</Table.Cell>
            <Table.Cell width={6} style={{ fontWeight: 600, color: Colors.DarkBlue200, fontStyle: 'italic' }}>
              <Dropdown
                upward={true}
                name="paymentMethod"
                placeholder="Direct Deposit"
                selection={true}
                value={paymentMethod}
                options={paymentMethodOptions}
                onChange={this.handleChange}
              />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </StyledTable>
    )
  }

  private handleChange = async (e: React.SyntheticEvent<HTMLDivElement> | any, { name, value }: any) => {
    const { commission } = this.props
    if (name === 'paymentMethod') {
      this.setState({
        paymentMethod: value
      })
      await updateUserPaymentMethod(commission.transaction.owner._id, value)
    } else {
      const result = value.split(', ')
      this.setState({
        selectedOption: {
          key: result[1],
          text: result[0],
          value
        }
      })
      await updateUserCommissionPlan(commission.transaction.owner._id, result[1])
    }
  }

  private handleClose = () => {
    this.setState({ openDatePicker: false })
  }

  private handleOpen = () => {
    this.setState({ openDatePicker: true })
  }

  private onChangeDate = async (date: any) => {
    const { commission } = this.props
    this.setState({ expiryData: date })
    await updateUserLicensedExpiration(commission.transaction.owner._id, date)
    this.handleClose()
  }
}

export default Transaction
