import axios from 'axios'
import { logoutTimer } from './LogoutTimer'

const interceptor = () => {
  axios.interceptors.request.use(async (req) => {
    logoutTimer()
    return {
      ...req,
    }
  })
}

export default interceptor
