import moment from 'moment'
import * as React from 'react'
import { connect } from 'react-redux'
import { AppState } from 'store/CombineReducers'
import { Chart } from 'react-google-charts'
import { Button, Form, Loader } from 'semantic-ui-react'
import ConfirmAlert from 'sweetalert2'

import Toast from 'shared/Toast'
import { paymentApproved, paymentReleased } from 'app/Transactions/Dashboard/TransactionMutations'
import { getTransactionToleranceChecker } from 'app/Transactions/Dashboard/TransactionQueries'
import NotesModal from '../NotesModal'
import Modal from './Modal'

import { Routes, Strings } from 'utils'

import {
  TransactionType,
  UserType,
} from 'app/Transactions/Details/Types'
import {
  Commission,
  ManagerNotes,
  TotalCommissions,
} from 'store/Transactions/Types'
import { ListData } from './Types'

import {
  ChartTitle,
  GraphWrapper,
  Labels,
  Notes,
  Wrapper,
} from './Styled'

import { faComment } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faComment])

interface Props {
  showManagerNotesModal: () => void
  closeModal: () => void
  approved: (approve: boolean) => void
  released: (release: boolean) => void
  user: UserType
  managerNotes: ManagerNotes[]
  commission: Commission
  totalCommissions: TotalCommissions
  transaction: TransactionType
  isApproved: boolean
  isReleased: boolean
  toggleNotes: boolean
  feeAdjustmentFlag: boolean
}
interface State {
  showManagerNotesModal: boolean
  commissionDue: number
  expectedCommission: number
  totalCredits: number
  totalDebits: number
  totalReferrals: number
  isReleased: boolean
  showPaymentModal: boolean
  title: string
}

class Graph extends React.Component<Props, State> {
  public state = {
    commissionDue: 0,
    expectedCommission: 0,
    isReleased: false,
    showManagerNotesModal: false,
    showPaymentModal: false,
    title: '',
    totalCredits: 0,
    totalDebits: 0,
    totalReferrals: 0
  }

  public componentWillReceiveProps(nextProps: any) {
    let commissionDue = 0
    if (nextProps.commission.actualCommission !== 0) {
      commissionDue = nextProps.totalCommissions.commissionDue - nextProps.totalCommissions.totalCredits
      this.setState({
        commissionDue: commissionDue < 0 ? 0 : commissionDue,
        totalCredits: nextProps.totalCommissions.totalCredits < 0 ? 0 : nextProps.totalCommissions.totalCredits,
        totalDebits: nextProps.totalCommissions.totalDebits < 0 ? 0 : nextProps.totalCommissions.totalDebits,
        totalReferrals: nextProps.totalCommissions.totalReferrals < 0 ? 0 : nextProps.totalCommissions.totalReferrals
      })
    } else {
      commissionDue = nextProps.totalCommissions.commissionDue - nextProps.totalCommissions.totalCredits
      this.setState({
        commissionDue: commissionDue < 0 ? 0 : commissionDue,
        totalCredits: nextProps.totalCommissions.totalCredits < 0 ? 0 : nextProps.totalCommissions.totalCredits,
        totalDebits: nextProps.totalCommissions.totalDebits < 0 ? 0 : nextProps.totalCommissions.totalDebits,
        totalReferrals: nextProps.totalCommissions.totalReferrals < 0 ? 0 : nextProps.totalCommissions.totalReferrals
      })
    }
  }

  public render() {
    const {
      isApproved,
      managerNotes,
      toggleNotes,
      transaction,
      user,
      feeAdjustmentFlag
    } = this.props
    const {
      commissionDue,
      showPaymentModal,
      title,
      totalCredits,
      totalDebits,
      totalReferrals,
    } = this.state
    return (
      <div>
        {toggleNotes && (
          <NotesModal
            closeModal={this.closeModal}
            transactionId={transaction._id}
            user={user}
          />
        )}
        {showPaymentModal && (
          <Modal
            closeModal={this.closeModal}
            title={title}
          />
        )}
        <GraphWrapper>
          <ChartTitle>{Strings.dashboard.commission.feeBreakdown}</ChartTitle>
          <Wrapper>
            <Chart
              width={'400px'}
              height={'400px'}
              chartType="PieChart"
              loader={
                <div style={{ marginTop: 200 }}>
                  <Loader active={true} inline="centered" size="massive" />
                </div>
              }
              data={[
                ['Real Time', 'Amount'],
                [`Total Due`, commissionDue],
                [`Debits`, totalDebits],
                [`Referrals`, totalReferrals],
                [`Credits`, totalCredits]
              ]}
              options={{
                legend: 'none'
              }}
              rootProps={{ 'data-testid': '1' }}
            />
            {(!isApproved && (user.role === 'ADMIN' || user.role === 'MANAGER')) && (
                <Form.Field
                  control={Button}
                  onClick={this.paymentApproved}
                  content={'Approve Payment'}
                  disabled={feeAdjustmentFlag}
                />
              )}
            {(isApproved && user.role === 'ADMIN' && transaction.status !== 'Closed') && (
                <Form.Field
                  control={Button}
                  onClick={this.paymentReleased}
                  content={'Release Payment'}
                />
              )}
          </Wrapper>
          {(user.role === 'ADMIN' || user.role === 'MANAGER') && (
            <Notes onClick={this.showManagerNotesModal}>
              <FontAwesomeIcon icon={['far', 'comment']} />
              <div>
                {Strings.dashboard.commission.managerNotes}
                <Labels circular={true}>{managerNotes.length}</Labels>
              </div>
            </Notes>
          )}
        </GraphWrapper>
      </div>
    )
  }

  private paymentApproved = async () => {
    const { commission, transaction, approved } = this.props
    if (transaction.progress === 100) {
      ConfirmAlert({
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Yes',
        showCancelButton: true,
        title: 'Approve Payment?',
        type: 'warning'
      }).then(async (result: any) => {
        if (result.value) {
          await paymentApproved(commission._id, true)
          approved(true)
          this.setState({ showPaymentModal: true, title: Strings.transactionDashboard.column.paymentApproved })
        } else {
          Toast({ message: 'Payment Not Approved', type: 'success' })
        }
      })
    } else {
      Toast({ message: 'Please approve remaining checklist items prior to approving payment', type: 'error' })
    }
  }

  private paymentReleased = async () => {
    const { commission, released, transaction } = this.props
    const response = await getTransactionToleranceChecker(transaction._id)
    const note = 'NOTE: The Following May Be Possible Duplicate Payments'
    let toleranceChecker = ''
    toleranceChecker = response
      .map(
        (list: ListData, index: number) => `<div style="margin-bottom: 4px;" key="${index}">
    <a href='${Routes.transactions.root +
      `/${list.transaction._id}` +
      Routes.transactions.documents.path}' target="_blank">${list.transaction.propertyId.address.streetNumber} ${
          list.transaction.propertyId.address.streetName
        } | $${list.actualCommission} | ${moment(list.receivedDate).format('L')}</a>
        </div>`
      )
      .toString()
    const removeExtraCommas = toleranceChecker.replace(/,/g, '')
    ConfirmAlert({
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Yes',
      html: `<div style="font-size: 24px; color: #4e5f80;margin-bottom: 12px;font-weight: bold;">Release Payment?</div><div style="font-size: 14px;font-weight: bold;color: red;margin-bottom: 6px;">${removeExtraCommas &&
        note}</div><div style="font-size: 14px;">${removeExtraCommas}</div>`,
      showCancelButton: true
    }).then(async (result: any) => {
      if (result.value) {
        await paymentReleased(commission._id, true)
        released(true)
        this.setState({ showPaymentModal: true, title: Strings.transactionDashboard.column.paymentReleased })
      } else {
        Toast({ message: 'Payment Not Released', type: 'success' })
      }
    })
  }

  private showManagerNotesModal = () => {
    const { showManagerNotesModal } = this.props
    showManagerNotesModal()
  }

  private closeModal = () => {
    const { closeModal } = this.props
    closeModal()
    this.setState({ showPaymentModal: false })
  }
}

const mapStateToProps = (state: AppState) => ({
  feeAdjustmentFlag: state.transactions.feeAdjustmentFlag
})

export default connect(
  mapStateToProps
)(Graph)
