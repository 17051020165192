import { merge as _merge } from 'lodash'
import { Reducer } from 'redux'

import Actions from 'store/Transactions/ActionsTypes'
import ProfileActions from 'store/UserProfile/ActionsTypes'
import {
  addCredits,
  addFee,
  addNewBoard,
  addNewCard,
  addNewManagerNote,
  addNewMessage,
  addPropertyImage,
  addReferralFee,
  addShareWith,
  addWorkflow,
  changeStatus,
  createTransactionDocuments,
  createWorkflowTask,
  deleteCard,
  deletecreditData,
  deleteFeeData,
  deleteManagerNote,
  deleteMessage,
  deleteMultipleWorkflowTasks,
  deletePropertyImage,
  deleteRecord,
  deleteReferralFee,
  deleteShareWith,
  deleteWorkflowTask,
  editcreditData,
  editFeeData,
  editManagerNote,
  editMessage,
  editRecord,
  editReferralFee,
  importData,
  moveTransaction,
  restoreCard,
  setNewBoardName,
  setNewDescription,
  setNewIcon,
  sortBoardData,
  transferTransaction,
  updateMultipleWorkflowTasks,
  updateWorkflowTask
} from './ReduxUtil'
import {
  TransactionList,
  TransactionPassFilter,
  TransactionState
} from './Types'

import { DispatchEnum } from 'store/TransactionV2/Types'
import {
  PropertyType as PropertyTypeV2,
  TransactionType as TransactionTypeV2,
} from 'app/Transactions/Details/Types'

export const initialState: TransactionState = {
  allTransactions: [],
  boardId: '',
  cardIndex: '',
  commission: {},
  confetti: false,
  creditDebit: [],
  credits: [],
  currentlyDeleteCard: null,
  documentChecklist: [],
  documentCount: 0,
  documents: [],
  fee: [],
  filter: {
    filter: false,
    searchText: false
  },
  hasTeamFlag:false,
  isApproved: false,
  isReleased: false,
  listData: [],
  managerNotes: [],
  messageCount: 0,
  messages: [],
  officeFilter: '',
  propertyImages: [],
  referralFee: [],
  searchData: {},
  searchLoader: false,
  shareWith: [],
  showDefaultView: true,
  showDocumentLayout: true,
  toggleNotesModal: false,
  toggleTab: false,
  totalCommission: {},
  transactionDetail: {},
  transactionOwnerID: '',
  feeAdjustmentFlag: false,
  userPassTransactionFilter: {} as TransactionPassFilter,
  workflowTask: [],
  percentageAmount:0
}

const TransactionReducer: Reducer<TransactionState> = (
  state: TransactionState = initialState,
  action
) => {
  switch (action.type) {

    // WIP: part of iterative redesign of Transaction cache
    case DispatchEnum.MergeTransaction: {
      const { payload } = action
      const current: TransactionTypeV2 = state.transactionDetail || {} as TransactionTypeV2
      const update: TransactionTypeV2 = _merge({}, current, payload)

      return {
        ...state,
        transactionDetail: { ...update },
      }
    }

    case DispatchEnum.MergeTransactionProperty: {
      const { payload } = action
      const current: PropertyTypeV2 = state.transactionDetail.propertyId || {} as PropertyTypeV2
      const update: PropertyTypeV2 = _merge({}, current, payload.data)

      return {
        ...state,
        transactionDetail: {
          ...state.transactionDetail,
          propertyId: { ...update }
        }
      }
    }

    case Actions.fetchAllRecentTransactions:
      return {
        ...state,
        allTransactions: action.payload.allTransactions,
        listData: action.payload.allListTransactions
      }
    case Actions.transactionAddNewBoard:
      return {
        ...state,
        allTransactions: addNewBoard(
          state,
          action.payload.index,
          action.payload.newData
        )
      }
    case Actions.transactionAddNewCard:
      return {
        ...state,
        allTransactions: addNewCard(
          state,
          action.payload.id,
          action.payload.index,
          action.payload.newData
        )
      }
    case Actions.transactionDelete:
      return {
        ...state,
        allTransactions: moveTransaction(
          state,
          action.payload.id,
          action.payload.status,
          action.payload.newData
        )
      }
    case Actions.transactionSetNewIcon:
      return {
        ...state,
        allTransactions: setNewIcon(
          state,
          action.payload.id,
          action.payload.icon
        )
      }
    case Actions.transactionSetNewDescription:
      return {
        ...state,
        allTransactions: setNewDescription(
          state,
          action.payload.id,
          action.payload.description
        )
      }
    case Actions.transactionSetNewBoardName:
      return {
        ...state,
        allTransactions: setNewBoardName(
          state,
          action.payload.id,
          action.payload.boardName
        )
      }
    case Actions.transactionDeleteCard:
      const { allTransactions } = state
      const feeData = allTransactions.slice()
      let index: string
      let temp: TransactionList
      const callback = () => {
        return {
          ...state,
          allTransactions: deleteCard(
            state,
            action.payload.boardId,
            action.payload.cardId
          ),
          boardId: action.payload.boardId,
          cardIndex: index,
          currentlyDeleteCard: temp
        }
      }
      feeData.forEach((list: any) => {
        if (list._id === action.payload.boardId) {
          const newList = list.items.slice()
          const listIndex = newList.findIndex((laneItem: any) => {
            return laneItem._id === action.payload.cardId
          })
          index = listIndex
          temp = newList[listIndex]
          callback()
        }
      })
      return callback()
    case Actions.transactionRestoreCard:
      return {
        ...state,
        allTransactions: restoreCard(
          state,
          state.boardId,
          state.cardIndex,
          state.currentlyDeleteCard
        )
      }
    case Actions.transactionResetCardData:
      return {
        ...state,
        boardId: '',
        cardIndex: '',
        currentlyDeleteCard: null
      }
    case Actions.transactionDeleteRecord:
      return {
        ...state,
        listData: deleteRecord(state, action.payload.cardId)
      }
    case Actions.transactionAddNewMessage:
      return {
        ...state,
        messages: addNewMessage(state, action.payload.newData)
      }
    case Actions.transactionGetMessageData:
      return {
        ...state,
        messages: action.payload.data
      }
    case Actions.transactionEditMessage:
      return {
        ...state,
        messages: editMessage(state, action.payload.id, action.payload.newData)
      }
    case Actions.transactionDeleteMessage:
      return {
        ...state,
        messages: deleteMessage(state, action.payload.id)
      }

    case Actions.transactionAddFee: {
      const fees = addFee(state, action.payload.newData)
      return {
        ...state,
        commission: {
          ...action.payload.newData.payment,
          ...state.commission,
          fees,
        },
        fee: fees
      }
    }

    case Actions.transactionGetFeeData:
      return {
        ...state,
        fee: action.payload.data
      }

    case Actions.transactionEditFeeData: {
      const fees = editFeeData(state, action.payload.id, action.payload.newData)
      return {
        ...state,
        commission: {
          ...action.payload.newData.payment,
          ...state.commission,
          fees,
        },
        fee: fees
      }
    }

    case Actions.transactionDeleteFeeData: {
      const fees = deleteFeeData(state, action.payload.id)
      return {
        ...state,
        commission: {
          ...state.commission,
          fees,
        },
        fee: fees
      }
    }

    case Actions.transactionAddCredits:
      return {
        ...state,
        commission: {
          ...action.payload.newData.payment,
          ...state.commission
        },
        credits: addCredits(state, action.payload.newData)
      }
    case Actions.transactionGetCreditsData:
      return {
        ...state,
        credits: action.payload.data
      }
    case Actions.transactionEditCreditsData:
      return {
        ...state,
        commission: {
          ...action.payload.newData.payment,
          ...state.commission
        },
        credits: editcreditData(
          state,
          action.payload.id,
          action.payload.newData
        )
      }
    case Actions.transactionDeleteCreditsData:
      return {
        ...state,
        credits: deletecreditData(state, action.payload.id)
      }
    case Actions.transactionEditRecord:
      return {
        ...state,
        listData: editRecord(state, action.payload.newData)
      }
    case Actions.transactionImportData: {
      return {
        ...state,
        allTransactions: importData(
          state,
          action.payload.boardId,
          action.payload.importedData
        )
      }
    }

    case Actions.transactionAddReferralFee: {
      const referrals = addReferralFee(state, action.payload.newData)
      return {
        ...state,
        commission: {
          ...state.commission,
          referrals
        },
        referralFee: referrals
      }
    }

    case Actions.transactionGetReferralFee:
      return {
        ...state,
        commission: {
          ...state.commission,
          referrals: action.payload.data,
        },
        referralFee: action.payload.data
      }

    case Actions.transactionEditReferralFee: {
      const referrals = editReferralFee(state, action.payload.id, action.payload.newData)
      return {
        ...state,
        commission: {
          ...state.commission,
          referrals
        },
        referralFee: referrals
      }
    }

    case Actions.transactionDeleteReferralFee: {
      const referrals = deleteReferralFee(state, action.payload.id)
      return {
        ...state,
        commission: {
          ...state.commission,
          referrals
        },
        referralFee: referrals
      }
    }

    case Actions.transactionAddNewManagerNote:
      return {
        ...state,
        managerNotes: addNewManagerNote(state, action.payload.newData)
      }
    case Actions.transactionGetManagerNotes:
      return {
        ...state,
        managerNotes: action.payload.data
      }
    case Actions.transactionEditManagerNote:
      return {
        ...state,
        managerNotes: editManagerNote(
          state,
          action.payload.id,
          action.payload.newData
        )
      }
    case Actions.transactionDeleteManagerNote:
      return {
        ...state,
        managerNotes: deleteManagerNote(state, action.payload.id)
      }
    case Actions.transactionShareWith:
      return {
        ...state,
        shareWith: addShareWith(state, action.payload.shareWithObj)
      }
    case Actions.getTransactionShareWith:
      return {
        ...state,
        shareWith: action.payload.data
      }
    case Actions.deleteTransactionShareWith:
      return {
        ...state,
        shareWith: deleteShareWith(state, action.payload.data)
      }
    case Actions.transactionDetails:
      return {
        ...state,
        transactionDetail: action.payload.data
      }
    case Actions.getTransactionWorkflowTask:
      return {
        ...state,
        workflowTask: action.payload.data
      }
    case Actions.createTransactionWorkflowTask:
      return {
        ...state,
        workflowTask: createWorkflowTask(state, action.payload.data)
      }
    case Actions.deleteTransactionWorkflowTask:
      return {
        ...state,
        workflowTask: deleteWorkflowTask(state, action.payload.data)
      }
    case Actions.updateTransactionWorkflowTask:
      return {
        ...state,
        workflowTask: updateWorkflowTask(state, action.payload.data)
      }
    case Actions.getTransactionDocumentChecklist:
      return {
        ...state,
        documentChecklist: action.payload.data
      }
    case Actions.updateTransactionDocumentChecklist:
      return {
        ...state,
        transactionDetail: {
          ...state.transactionDetail,
          progress: action.payload.data.transaction.progress
        }
      }
    case Actions.updateTransactionAllDocumentChecklist:
      return {
        ...state,
        transactionDetail: {
          ...state.transactionDetail,
          progress:
            action.payload.data[action.payload.data.length - 1].transaction
              .progress
        }
      }
    case Actions.getTransactionDocuments:
      return {
        ...state,
        documents: action.payload.data
      }
    case Actions.createTransactionDocument:
      return {
        ...state,
        documents: createTransactionDocuments(state, action.payload.data)
      }
    case Actions.transactionAddWorkflow:
      return {
        ...state,
        workflowTask: addWorkflow(state, action.payload.newData)
      }
    case Actions.transactionGetWorkflow:
      return {
        ...state,
        workflowTask: action.payload.data
      }
    case Actions.deleteMultipleTransactionWorkflowTask:
      return {
        ...state,
        workflowTask: deleteMultipleWorkflowTasks(state, action.payload.ids)
      }
    case Actions.updateMultipleTransactionWorkflowTask:
      return {
        ...state,
        workflowTask: updateMultipleWorkflowTasks(
          state,
          action.payload.ids,
          action.payload.status
        )
      }

    case Actions.propertyImagesSet:
      return {
        ...state,
        propertyImages: action.payload.images,
        transactionDetail: {
          ...state.transactionDetail,
          propertyId: {
            ...state.transactionDetail.propertyId,
            images: action.payload.images
          }
        }
      }

    case Actions.propertyImageAdd: {
      const images = addPropertyImage(state, action.payload.image)
      return {
        ...state,
        propertyImages: images,
        transactionDetail: {
          ...state.transactionDetail,
          propertyId: {
            ...state.transactionDetail.propertyId,
            images
          }
        }
      }
    }

    case Actions.propertyImageDelete: {
      const images = deletePropertyImage(state, action.payload.id)
      return {
        ...state,
        propertyImages: images,
        transactionDetail: {
          ...state.transactionDetail,
          propertyId: {
            ...state.transactionDetail.propertyId,
            images
          }
        }
      }
    }

    case Actions.transactionSortBoardData:
      return {
        ...state,
        allTransactions: sortBoardData(
          state,
          action.payload.boardId,
          action.payload.sortOrder
        )
      }
    case Actions.getCommission:
      return {
        ...state,
        commission: action.payload.data
      }
    case Actions.totalCommission:
      return {
        ...state,
        totalCommission: action.payload.data,
        commission: {
          ...state.commission,
          ...action.payload.data,
        }
      }
    case Actions.documentCount:
      return {
        ...state,
        documentCount: action.payload.count
      }
    case Actions.messageCount:
      return {
        ...state,
        messageCount: action.payload.count
      }
    case Actions.paymentApprove:
      return {
        ...state,
        isApproved: action.payload.isApproved
      }
    case Actions.paymentReleased:
      return {
        ...state,
        isReleased: action.payload.isReleased
      }
    case Actions.transactionModal:
      return {
        ...state,
        toggleNotesModal: action.payload.open
      }
    case ProfileActions.getUserCreditDebit:
      return {
        ...state,
        creditDebit: action.payload.data
      }
    case Actions.changeCreditDebitStatus:
      return {
        ...state,
        creditDebit: changeStatus(
          state,
          action.payload.accountId,
          action.payload.status
        )
      }
    case Actions.transactionTab:
      return {
        ...state,
        toggleTab: action.payload.open
      }
    case Actions.transferTransaction:
      return {
        ...state,
        allTransactions: transferTransaction(
          state,
          action.payload.cardId,
          action.payload.destinationBoardId,
          action.payload.index,
          action.payload.sourceBoardId
        )
      }
    case Actions.transactionFilter:
      return {
        ...state,
        filter: action.payload.data
      }
    case Actions.transactionSearchLoader:
      return {
        ...state,
        searchLoader: action.payload.open
      }
    case Actions.transactionShowDefaultView:
      return {
        ...state,
        showDefaultView: action.payload.open
      }
    case Actions.transactionSearchData:
      return {
        ...state,
        searchData: action.payload.data
      }
    case Actions.transactionShowDocumentLayout:
      return {
        ...state,
        showDocumentLayout: action.payload.open
      }
    case Actions.transactionOfficeFilter:
      return {
        ...state,
        officeFilter: action.payload.data
      }
    case Actions.transactionOwnerID:
      return {
        ...state,
        transactionOwnerID: action.payload.data
      }
    case Actions.resetState:
      return {
        ...state,
        boardId: '',
        cardIndex: '',
        commission: {},
        creditDebit: [],
        credits: [],
        currentlyDeleteCard: null,
        documentChecklist: [],
        documentCount: 0,
        documents: [],
        fee: [],
        filter: {
          filter: false,
          searchText: false
        },
        isApproved: false,
        isReleased: false,
        managerNotes: [],
        messageCount: 0,
        messages: [],
        propertyImages: [],
        referralFee: [],
        searchData: {},
        searchLoader: false,
        shareWith: [],
        showDefaultView: true,
        showDocumentLayout: true,
        toggleNotesModal: false,
        toggleTab: false,
        totalCommission: {},
        transactionDetail: {},
        workflowTask: []
      }
    case Actions.transactionEditOwnership:
      return {
        ...state,
        transactionDetail: {
          ...state.transactionDetail,
          owner: action.payload.data.owner
        }
      }
    case Actions.userPassTransactionFilter:
      return {
        ...state,
        userPassTransactionFilter: action.payload.filters
      }
    case Actions.transactionConfetti:
      return {
        ...state,
        confetti: action.payload.data
      }
    case Actions.setPercentageAmount:
        return {
          ...state,
          percentageAmount: action.payload,
        }
    case Actions.setFeeAdjustmentFlag:
          return {
            ...state,
            feeAdjustmentFlag: action.payload,
        }
    case Actions.setHasTeamFlag:
          return {
            ...state,
            hasTeamFlag: action.payload,
        }

    default:
      return state
  }
}

export default TransactionReducer
