// Import Packages
import * as React from 'react'
import { Dropdown, Icon } from 'semantic-ui-react'
import ConfirmAlert from 'sweetalert2'

import { TeamItemsDetails } from 'store/Teams/Types'

// Import Components
import { deleteCard, editTeam, ShowInvited, SingleCheckBox, ToggleCheckBox } from './Teams'

import { ShowComposeMail } from './Teams'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import {
  // ChatIcon,
  CheckboxWrapper,
  DropDownPopup,
  EmailIcon,
  FullName,
  Invites,
  Labels,
  Leader,
  Member,
  MemberProfile,
  Pending,
  PhoneIcon,
  ProfileImage,
  StyledCheckbox,
  StyledDropdown,
  StyledLabel,
  StyledPopup,
  TeamCAP,
  TeamOffice
} from './Styled'

let checked = false

export const Checked = (data: boolean) => {
  checked = !data
}

export const CustomHeaderCheckBox = () => {
  const onChange = () => {
    ToggleCheckBox()
  }
  return (
    <CheckboxWrapper>
      <StyledCheckbox checked={checked} onClick={onChange} />
    </CheckboxWrapper>
  )
}

export const CustomCheckBox = ({ rowData }: { rowData: TeamItemsDetails }) => {
  const onChange = () => {
    SingleCheckBox(rowData._id)
  }
  return (
    <CheckboxWrapper>
      <StyledCheckbox checked={rowData.checked} onChange={onChange} />
    </CheckboxWrapper>
  )
}

export const CustomActions = ({ rowData }: { rowData: TeamItemsDetails }) => {
  const showInvited = () => {
    ShowInvited(rowData._id, 0)
  }
  const editTeamDetails = () => {
    editTeam(rowData)
  }
  const deleteTeam = () => {
    ConfirmAlert({
      cancelButtonText: Strings.kanbanView.noKeepIt,
      confirmButtonText: Strings.kanbanView.yesDeleteIt,
      showCancelButton: true,
      text: Strings.kanbanView.recoverRecord,
      title: Strings.kanbanView.sure,
      type: 'warning'
    }).then(result => {
      if (result.value) {
        deleteCard(rowData._id)
        ConfirmAlert(Strings.kanbanView.deleted, Strings.kanbanView.deletedRecord, 'success')
      } else if (result.dismiss === ConfirmAlert.DismissReason.cancel) {
        ConfirmAlert(Strings.kanbanView.cancelled, Strings.kanbanView.safeRecord, 'error')
      }
    })
  }
  return (
    <StyledDropdown>
      <DropDownPopup
        content={
          <div className="action-drop-down">
            <Dropdown.Menu>
              <Dropdown.Item text={Strings.teams.viewDetails} onClick={showInvited} />
              {rowData.showAddButton && <Dropdown.Item text={Strings.teams.edit} onClick={editTeamDetails} />}
              {rowData.showAddButton && <Dropdown.Item text={Strings.teams.delete} onClick={deleteTeam} />}
            </Dropdown.Menu>
          </div>
        }
        trigger={<Icon style={{ cursor: 'pointer' }} name="caret down" />}
        hoverable={true}
        position="bottom left"
      />
    </StyledDropdown>
  )
}

export const CustomTeamName = ({ rowData }: { rowData: TeamItemsDetails }) => {
  const showInvited = () => {
    ShowInvited(rowData._id, 0)
  }
  return <TeamOffice onClick={showInvited}>{rowData.teamName}</TeamOffice>
}

export const CustomTeamOffice = ({ rowData }: { rowData: TeamItemsDetails }) => {
  return <TeamOffice>{rowData.branchName}</TeamOffice>
}

export const CustomTeamLeader = ({ rowData }: { rowData: TeamItemsDetails }) => {
  return (
    <Leader>
      <ProfileImage background={rowData.profileImage} />
      <FullName>{[rowData.firstName, rowData.lastName].join(' ')}</FullName>
      <StyledPopup
        trigger={
          <EmailIcon
            onClick={e => {
              e.stopPropagation()
              ShowComposeMail(rowData.email)
            }}
          />
        }
        content={rowData.leader.userName ? rowData.leader.userName : 'No Email Added '}
      />
      <StyledPopup
        trigger={<PhoneIcon />}
        content={
          rowData.leader.phones && rowData.leader.phones[0] ? rowData.leader.phones[0].value : 'No Phone Number Added'
        }
      />
      {/* <ChatIcon /> */}
    </Leader>
  )
}

export const CustomTeamCAP = ({ rowData }: { rowData: TeamItemsDetails }) => {  
  return (
  <TeamCAP>{`$${rowData.teamCap?.residentialTargetCap}/$${rowData.teamCap?.commercialTargetCap}`}</TeamCAP>
)
}

export const CustomTeamMembers = ({ rowData }: { rowData: TeamItemsDetails }) => {
  const membersFilters = rowData.members.filter((user: any) => {
    return user.status === 'Active' && user.role !== 'TEAM_LEADER'
  })
  if (rowData.members.length > 0) {
    return (
      <Member>
        {membersFilters.map((items: any, index: number) => {
          const left = index * 15
          while (index < 4) {
            return (
              <StyledPopup
                trigger={<MemberProfile key={index} background={items.profileImage} left={left} />}
                content={`${items.firstName} ${items.lastName}`}
              />
            )
          }
          return null
        })}
        {membersFilters.length > 3 ? (
          <Labels>
            <StyledPopup trigger={<StyledLabel>...</StyledLabel>} content={`+${membersFilters.length - 3}`} />
          </Labels>
        ) : null}
      </Member>
    )
  } else {
    return <FullName>{Strings.teams.noMembers}</FullName>
  }
}

export const CustomPandingInvites = ({ rowData }: { rowData: TeamItemsDetails }) => {
  const showPendingTab = () => {
    ShowInvited(rowData._id, 1)
  }
  const pendingMembersFilters = rowData.members.filter((user: any) => {
    return user.status === 'Pending' && user.role !== 'TEAM_LEADER'
  })
  return (
    <Pending>
      {
        <Invites circular={true} onClick={showPendingTab}>
          {pendingMembersFilters.length}
        </Invites>
      }
    </Pending>
  )
}
