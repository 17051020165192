import * as React from 'react'
import { connect } from 'react-redux'
import ConfirmAlert from 'sweetalert2'

import LoadingIndicator from 'shared/LoadingIndicator'
import ComposeModal from 'shared/MessageEmail/ComposeModal'
import Modal from 'shared/Modal'
import StyledGrid from 'shared/StyledBoard/GridView'
import KanbanView from 'shared/StyledBoard/KanbanView'
import Toast from 'shared/Toast'

import AddTeamModal from './AddTeamModal'
import {
  CustomActions,
  CustomCheckBox,
  CustomHeaderCheckBox,
  CustomPandingInvites,
  CustomTeamCAP,
  CustomTeamLeader,
  CustomTeamMembers,
  CustomTeamName,
  CustomTeamOffice
} from './CustomComponents'
import { Icons } from './Icons'
import InviteModal from './InviteModal'
import StyledHeader from './StyledHeader'
import TeamCard from './TeamCard'

import { GET_TEAMS } from 'queries/graphql/Teams/Queries'
import TeamQuery from './TeamQuery'
import { addNewTeam, deleteTeam, deleteTeams, moveTeams, reorderCard, reorderLane, restore } from './TeamsMutations'
import {
  ChangeToggleCheckBox,
  getOfficesTeam,
  getOfficeTeam,
  getOptions,
  getTeamOnOffice,
  getTeams,
  reset,
  sortTeam,
  ToggleSingleCheckBox
} from './TeamsQueries'

import { cacheData } from 'queries/apollo'
import { AppState } from 'store/CombineReducers'
import * as Dashboard from 'store/Dashboard/Actions'
import * as Actions from 'store/Teams/Actions'
import TeamAction from './Actions'

import { IndicatorTypeEnum } from 'shared/LoadingIndicator/Types'
import { Filter, FiltersData, Options, TeamDetails, TeamItemsDetails, UserType } from 'store/Teams/Types'

import { getLoggedInUser, Strings } from 'utils'
import { formatKanbanData, formatListData } from './Utils/FormattingData'

import { Container, Error, ErrorContainer, ErrorText } from './Styled'

interface StoreProps {
  data: TeamDetails[]
  filter: Filter
  setBoardData: (data: TeamDetails[]) => void
  setShowTeamDetail: (data: string) => void
  setSearchDataFilter: (data: FiltersData) => void
  searchLoader: boolean
  searchData: FiltersData
  teamID: string
}

type Props = StoreProps

interface State {
  activeIndex: number
  listViewData?: TeamItemsDetails[]
  boardName?: string
  showAddTeamModal: boolean
  showInvitedModal: boolean
  checked: boolean
  show: boolean
  offices: Options[]
  filterState: string
  appearance: boolean
  boardId: string
  addTeamIndex: number
  editTeamCard: object
  edit: boolean
  deleteTeamId: string
  teamFilter: boolean
  detailCardId: string
  teamSort: boolean
  loader: boolean
  showComposeMail: string
  user: UserType
}

export let ShowInvited: any
export let ToggleCheckBox: any
export let SingleCheckBox: any
export let editTeam: any
export let deleteCard: any
export let sortTeams: any
export let ShowComposeMail: any
export let GetTeamDetail: any

// let isDataAvailable : any[] = []

class Teams extends React.Component<Props, State> {
  public state = {
    activeIndex: 0,
    addTeamIndex: 0,
    appearance: true,
    boardId: '',
    boardName: '',
    checked: false,
    deleteTeamId: '',
    detailCardId: '',
    edit: false,
    editTeamCard: {},
    filterState: 'Choose',
    listViewData: [],
    loader: true,
    offices: [],
    show: true,
    showAddTeamModal: false,
    showComposeMail: '',
    showInvitedModal: false,
    teamFilter: false,
    teamSort: false,
    user: {} as UserType
  }

  public columnMetaData = [
    {
      customComponent: CustomCheckBox,
      customHeadingComponent: CustomHeaderCheckBox,
      enhanceWithRowData: true,
      id: Strings.teams.checkbox,
      width: '1%'
    },
    {
      customComponent: CustomTeamName,
      enhanceWithRowData: true,
      id: Strings.teams.teamName,
      title: Strings.teams.TeamName
    },
    {
      customComponent: CustomTeamLeader,
      enhanceWithRowData: true,
      id: Strings.teams.teamLeader,
      title: Strings.teams.TeamLeader
    },
    {
      customComponent: CustomTeamOffice,
      enhanceWithRowData: true,
      id: Strings.teams.branchName,
      title: Strings.teams.TeamOffice
    },
    {
      customComponent: CustomTeamCAP,
      enhanceWithRowData: true,
      id: Strings.teams.teamCAP,
      title: Strings.teams.TeamCAP
    },
    {
      customComponent: CustomTeamMembers,
      enhanceWithRowData: true,
      id: Strings.teams.teamMembers,
      sortable: false,
      title: Strings.teams.TeamMembers
    },
    {
      customComponent: CustomPandingInvites,
      enhanceWithRowData: true,
      id: Strings.teams.pandingInvites,
      title: Strings.teams.PandingInvites
    },
    {
      customComponent: CustomActions,
      enhanceWithRowData: true,
      id: Strings.teams.action,
      sortable: false,
      title: Strings.teams.Action
    }
  ]

  public async componentDidMount() {
    const { setSearchDataFilter } = this.props
    const options = await getOptions()
    const user: any = await getLoggedInUser({ fromCache: true })
    if (user) {
      this.setState({ user })
    }
    setSearchDataFilter({})
    this.setState({ offices: options })
    this.refreshGetTeams()
    this.assignshowInvited()
    this.assignToggleCheckBox()
    this.assignSingleCheckBox()
    this.assignGetTeamDetail()
    this.editTeam()
    this.deleteTeam()
    this.sortOfficeTeams()
    this.assignShowComposeMail()
  }

  public render() {
    const { searchLoader, data: kanbanData, teamID } = this.props
    const {
      showAddTeamModal,
      showInvitedModal,
      offices,
      appearance,
      boardId,
      edit,
      editTeamCard,
      detailCardId,
      activeIndex,
      showComposeMail,
      filterState,
      loader,
      user
    } = this.state
    return (
      <React.Fragment>
        {showComposeMail && <ComposeModal onClose={this.closeModal} defaultEmail={showComposeMail} />}
        {showAddTeamModal && (
          <AddTeamModal
            closeModal={this.closeModal}
            addNewTeam={this.addNewTeam}
            boardId={boardId}
            edit={edit}
            teamCard={editTeamCard}
          />
        )}
        {(showInvitedModal || teamID) && (
          <InviteModal closeModal={this.closeModal} cardId={teamID ? teamID : detailCardId} activeIndex={activeIndex} />
        )}
        <Container>
          {(loader || searchLoader) && <LoadingIndicator type={IndicatorTypeEnum.Spinner} />}
          <StyledHeader
            user={user}
            addList={this.addList}
            showDefaultView={this.showDefaultView}
            offices={offices}
            filterValue={filterState}
            getOfficeTeams={this.getOfficeTeams}
            deleteTeams={this.deleteTeams}
          />
          {this.state.show ? (
            <KanbanView
              data={kanbanData}
              CardComponent={TeamCard}
              ActionComponent={TeamAction}
              reorderCard={this.reorderTeams}
              reorderColumn={this.reorderColumn}
              moveCard={this.moveTeams}
              addNewCard={this.addNewCard}
              icons={Icons}
              alterData={this.alterData}
              deleteCard={this.deleteCard}
              resetCardData={this.resetCardData}
              restoreCard={this.restoreCard}
              appearance={appearance}
              updateBoard={this.updateBoard}
            />
          ) : (
              <TeamQuery query={GET_TEAMS} variables={{ isActive: true }}>
                {({ loading, error, data }) => {
                 
                  if (loading) {
                    return <LoadingIndicator type={IndicatorTypeEnum.Spinner} />
                  }
                  if (error) {
                    return (
                      <Modal
                        content={
                          <ErrorContainer>
                            <Error>{Strings.teams.error}</Error>
                            <ErrorText>{Strings.teams.somethingWentWrong}</ErrorText>
                          </ErrorContainer>
                        }
                        closeModal={this.closeModal}
                        width={400}
                      />
                    )
                  }
                  if (data && data.getTeams) {
                    // const listViewData = isDataAvailable.length ? isDataAvailable :formatListData(data.getTeams)
                    const listViewData = formatListData(data.getTeams)
                    // if(!isDataAvailable.length){
                    //   isDataAvailable = listViewData
                    // }
                    
                    return (
                      <StyledGrid
                        data={listViewData}
                        tableHeight={50}
                        columnMetaData={this.columnMetaData}
                        addList={this.addList}
                      />
                    )
                  }
                  return null
                }}
              </TeamQuery>
            )}
        </Container>
      </React.Fragment>
    )
  }

  private refreshGetTeams = async (id?: string, singleSkip?: number) => {
    const { setBoardData, searchData } = this.props
    const { show, filterState } = this.state
    this.setState({ loader: true })
    if (show) {
      if (filterState === 'All' || filterState === 'Choose') {
        const kanbanData = await getOfficeTeam(searchData, id, singleSkip)
        const formatedData = formatKanbanData(kanbanData, this.state.teamSort)
        setBoardData(formatedData)
      } else {
        const kanbanData = await getOfficesTeam(filterState.split(', ')[0], searchData, id, singleSkip)
        const formatedData = formatKanbanData(kanbanData, this.state.teamSort)
        setBoardData(formatedData)
      }
      this.setState({ loader: false })
    } else {
      let kanbanData
      if (filterState === 'All' || filterState === 'Choose') {
        kanbanData = await getTeams(searchData)
      } else {
        kanbanData = await getTeamOnOffice(searchData, filterState.split(', ')[0])
      }
      cacheData.writeQuery({
        data: { getTeams: kanbanData },
        query: GET_TEAMS,
        variables: { isActive: true }
      })
      this.setState({ loader: false })
    }
  }

  private assignShowComposeMail = () => {
    ShowComposeMail = (email: string) => {
      this.setState({ showComposeMail: email })
    }
  }

  private showDefaultView = async (data: boolean) => {
    this.setState({ show: data, filterState: 'Choose' }, () => {
      this.refreshGetTeams()
    })
  }

  private deleteTeam = () => {
    deleteCard = async (cardId: string) => {
      const options = await deleteTeam(cardId)
      this.setState({ offices: options })
      this.refreshGetTeams()
    }
  }

  private assignToggleCheckBox = () => {
    ToggleCheckBox = () => {
      this.setState({ checked: !this.state.checked })
      const { checked } = this.state
      ChangeToggleCheckBox(checked)
    }
  }

  private assignSingleCheckBox = () => {
    SingleCheckBox = (id: string) => {
      ToggleSingleCheckBox(id)
    }
  }

  private assignGetTeamDetail = () => {
    GetTeamDetail = async (id: string) => {
      this.refreshGetTeams()
    }
  }

  private assignshowInvited = () => {
    ShowInvited = (detailCardId: string, activeIndex?: number) => {
      this.setState({
        activeIndex: activeIndex ? activeIndex : 0,
        detailCardId: detailCardId,
        showInvitedModal: true
      })
    }
  }

  private addList = () => {
    this.setState({
      boardId: '',
      edit: false,
      editTeamCard: {},
      showAddTeamModal: true
    })
  }

  private resetCardData = async () => {
    const options = await reset()
    this.setState({ offices: options })
    this.refreshGetTeams()
  }

  private restoreCard = async (boardId: string, cardId: string) => {
    const options = await restore(cardId)
    this.setState({ offices: options })
    this.refreshGetTeams()
  }

  private reorderTeams = async (boardId: string, cardId: string, sourceIndex: number, destinationIndex: number) => {
    const { filterState } = this.state
    if (filterState === 'All' || filterState === 'Choose') {
      await reorderCard(boardId, cardId, sourceIndex, destinationIndex)
      this.refreshGetTeams()
    }
  }

  private reorderColumn = async (boardId: string, sourceIndex: number, destinationIndex: number) => {
    const { filterState } = this.state
    if (filterState === 'All' || filterState === 'Choose') {
      await reorderLane(boardId, sourceIndex, destinationIndex)
      this.refreshGetTeams()
    }
  }

  private alterData = (data: TeamDetails[]) => {
    const { setBoardData } = this.props
    setBoardData(data)
  }

  private deleteCard = async (boardId: string, cardId: string) => {
    try {
      const options = await deleteTeam(cardId)
      this.setState({ offices: options })
      ConfirmAlert(Strings.kanbanView.deleted, Strings.kanbanView.deletedCard, 'success')
    } catch (error) {
      Toast({ message: error.message, type: 'error' })
    }
  }

  private closeModal = () => {
    const { setShowTeamDetail } = this.props
    this.setState({ showAddTeamModal: false, showInvitedModal: false, showComposeMail: '' })
    setShowTeamDetail('')
  }

  private addNewTeam = async (newTeamData: any) => {
    const { boardId, addTeamIndex, show, edit } = this.state
    const options = await addNewTeam(newTeamData, boardId, addTeamIndex, show, edit)
    this.refreshGetTeams()
    this.setState({ offices: options, filterState: 'Choose' })
  }

  private editTeam = () => {
    editTeam = (teamDetails: TeamItemsDetails) => {
      this.setState({
        boardId: '',
        edit: true,
        editTeamCard: teamDetails,
        showAddTeamModal: true
      })
    }
  }

  private addNewCard = (id: string, index: string) => {
    this.setState({
      addTeamIndex: parseInt(index),
      boardId: id,
      edit: false,
      editTeamCard: {},
      showAddTeamModal: true
    })
  }

  private getOfficeTeams = async (id: string, office: string) => {
    this.setState({ filterState: office }, () => this.refreshGetTeams())
  }

  private deleteTeams = async () => {
    const result = await deleteTeams()
    if (result !== 0 && result !== undefined) {
      this.refreshGetTeams()
    }
  }

  private sortOfficeTeams = () => {
    sortTeams = async (boardId: string, sortOrder: number) => {
      const { setBoardData } = this.props
      this.setState({ teamSort: true })
      const response = await sortTeam(boardId, sortOrder)
      if (boardId !== '') {
        const formatedData = formatKanbanData(response.data.getOfficeTeams, this.state.teamSort)
        setBoardData(formatedData)
      } else {
        const formatedData = formatKanbanData(response.data.getTeams, this.state.teamSort)
        setBoardData(formatedData)
      }
    }
  }

  private moveTeams = async (cardId: string, destinationIndex: number, boardId: string) => {
    const options = await moveTeams(cardId, destinationIndex, boardId)
    this.setState({ offices: options })
  }

  private updateBoard = async (id: string, singleSkip: number) => {
    this.refreshGetTeams(id, singleSkip)
  }
}

const mapStateToProps = (state: AppState) => ({
  data: state.teams.data,
  filter: state.teams.filter,
  searchData: state.teams.searchData,
  searchLoader: state.teams.searchLoader,
  teamID: state.dashboard.teamID
})

export default connect(
  mapStateToProps,
  {
    setBoardData: Actions.getBoardData,
    setSearchDataFilter: Actions.searchDataFilter,
    setShowTeamDetail: Dashboard.showTeamDetail
  }
)(Teams)
