import gql from 'graphql-tag'

export const userFragment = gql`
  fragment UserFragment on User {
    _id
    emails {
      type
      value
    }
    firstName
    lastName
    phones {
      smsEnabled
      type
      value
    }
    profileImage
    role
    status
    type
    userName
    hasTeam
  }
`

export const propertyFragment = gql`
  fragment PropertyFragment on Property {
    _id
    address {
      _id
      city
      country
      state
      streetName
      streetNumber
      type
      zipCode
    }
    bathrooms
    bedrooms
    description
    images {
      _id
      name
      order
      url
    }
    mlsId
    price
    squareFt
    subType {
      _id
      name
    }
    type {
      _id
      name
    }
  }
`

export const publicPropertyFragment = gql`
  fragment PublicPropertyFragment on PublicProperty {
    _id
    address {
      _id
      city
      country
      state
      streetName
      streetNumber
      type
      zipCode
    }
    bathrooms
    bedrooms
    description
    images {
      _id
      name
      order
      url
    }
    mlsId
    price
    squareFt
    subType {
      _id
      name
    }
    type {
      _id
      name
    }
  }
`

export const transactionFragment = gql`
  fragment TransactionFragment on Transaction {
    _id
    contact {
      _id
    }
    createdAt
    isActive
    officeTransactionBoard {
      office {
        _id
      }
    }
    owner {
      ...UserFragment
      licensedExpiration
      paymentMethod
      transactionFeeSetting {
        _id
        name
      }
      userCap {
        commercialCapStatus
        commercialCompletedCap
        commercialTargetCap
        residentialCapStatus
        residentialCompletedCap
        residentialTargetCap
      }
    }
    propertyId {
      ...PropertyFragment
    }
    payment {
      _id
      actualCommission
      closingDate
      commissionDue
      expectedCommission
      receivedDate
      totalCredits
      totalDebits
      totalIncome
      totalReferrals
      payViaEscrowAmount
    }
    officeTransactionOrder
    progress
    status
    tags
    teamTransactionOrder
    tenantTransactionOrder
    transactionEmail
    transactionId
    transactionRole
    updatedAt
    shareWith {
      ...UserFragment
    }
  }
  ${propertyFragment}
  ${userFragment}
`

export const referralFragment = gql`
  fragment ReferralFragment on Referral {
    _id
    accountNumber
    address {
      city
      country
      state
      streetName
      streetNumber
      type
      zipCode
    }
    amount
    bankName
    brokerageName
    brokerageTaxID
    description
    feeObj {
      _id
      fee
      name
      type
    }
    name
    office {
      _id
    }
    paymentMethod
    phone
    routingNumber
    type
    user {
      _id
      firstName
      lastName
    }
  }
`

export const paymentFragment = gql`
  fragment PaymentFragment on Payment {
    _id
    commissionDue
    totalCredits
    totalDebits
    totalExpenses
    totalIncome
    totalReferrals
  }
`
