import Individual from 'design/icons/settings/individual.png'
import Team from 'design/icons/settings/team.png'
import * as React from 'react'
import { Component } from 'react'
import { connect } from 'react-redux'
import Header from 'semantic-ui-react/dist/commonjs/elements/Header'
import client from 'queries/apollo'
import { GET_CAP_SETTING } from 'queries/graphql/Settings/TransactionFee/CapSettings/Queries'
import { UPDATE_CAP_SETTING_LOCAL } from 'queries/graphql/Settings/TransactionFee/Local/CapSettingLocalMutation'
import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Settings/TransactionFee/Actions'
import Strings from 'utils/Strings'
import CapSettingQuery from './CapSettingQuery'
import SettingsGroup from './SettingsGroup'
import SliderGroup from './SliderGroup'
import {
  ExclusionContainer,
  ExclusionSegment,
  HeaderContainer,
  SettingsSegment,
  StyledHeader,
  StyledSegmentGroup,
  StyledSwitch,
  Wrapper
} from './Styled'

interface Props {
  max: number
  min: number
  exclusionMax: number
  exclusionMin: number
  exclusionCap: number
  changeExclusionCap: (newValue: number) => void
  changeCapStatus: (toggleCapStatus : boolean) => void
}

interface CapData {
  individualResidentialCap?: number
  individualCommercialCap?: number
  // teamCap?: number
  teamResidentialCap?: number
  teamCommercialCap?: number
  additionalMemberCap?: number
  capStatus?: string
}

class CapsSettings extends Component<Props, {}> {
  private transactionFeeId = ''

  public onChangeResidentialCap = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.changeRemoteData({ individualResidentialCap: parseInt(e.target.value, 10) })
  }

  public onChangeCommercialCap = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.changeRemoteData({ individualCommercialCap: parseInt(e.target.value, 10) })
  }

  // public onChangeTeamCap = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   this.changeRemoteData({ teamCap: parseInt(e.target.value, 10) })
  // }

  public onChangeTeamResidentialCap = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.changeRemoteData({ teamResidentialCap: parseInt(e.target.value, 10) })
  }

  public onChangeTeamCommercialCap = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.changeRemoteData({ teamCommercialCap: parseInt(e.target.value, 10) })
  }

  public onChangeAdditionalCap = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.changeRemoteData({ additionalMemberCap: parseInt(e.target.value, 10) })
  }

  public onChangeExclusionCap = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { changeExclusionCap } = this.props
    changeExclusionCap(parseInt(e.target.value, 10))
  }

  public changeRemoteData(newData: CapData) {
    client.mutate({
      mutation: UPDATE_CAP_SETTING_LOCAL,
      variables: { _id: this.transactionFeeId, ...newData }
    })
  }

  public toggleCaps = (checked: string) => {
    const {changeCapStatus} = this.props
    changeCapStatus(checked === 'ON' ? true : false)
    this.changeRemoteData({ capStatus: checked })

  }

  public render() {
    const { min, max, exclusionMax, exclusionMin, exclusionCap } = this.props
    return (
      <CapSettingQuery query={GET_CAP_SETTING}>
        {({ loading, error, data }) => {
          if (loading) {
            return 'Loading...'
          }
          if (error) {
            return `Error! ${error.message}`
          }
          if (data && data.getCapSettings && data.getCapSettings[0]) {
            this.transactionFeeId = data.getCapSettings[0]._id
            const IndividualData = [
              {
                info: Strings.transactionFeeSettings.capGroups.residentialCapInfo,
                max,
                min,
                name: Strings.transactionFeeSettings.capGroups.residentialCap,
                onChange: this.onChangeResidentialCap,
                value: data.getCapSettings[0].individualResidentialCap
              },
              {
                info: Strings.transactionFeeSettings.capGroups.commercialCapInfo,
                max,
                min,
                name: Strings.transactionFeeSettings.capGroups.commercialCap,
                onChange: this.onChangeCommercialCap,
                value: data.getCapSettings[0].individualCommercialCap
              }
            ]
            const TeamData = [
              {
                info: Strings.transactionFeeSettings.capGroups.teamCapInfo,
                max,
                min,
                name: Strings.transactionFeeSettings.capGroups.teamResidentialCap,
                onChange: this.onChangeTeamResidentialCap,
                value: data.getCapSettings[0].teamResidentialCap
              },
              {
                info: Strings.transactionFeeSettings.capGroups.teamCapInfo,
                max,
                min,
                name: Strings.transactionFeeSettings.capGroups.teamCommercialCap,
                onChange: this.onChangeTeamCommercialCap,
                value: data.getCapSettings[0].teamCommercialCap
              },
              {
                info: Strings.transactionFeeSettings.capGroups.additionalCapInfo,
                max,
                min,
                name: Strings.transactionFeeSettings.capGroups.additionalCap,
                onChange: this.onChangeAdditionalCap,
                value: data.getCapSettings[0].additionalMemberCap
              }
            ]
            return (
              <StyledSegmentGroup horizontal={true}>
                <SettingsSegment basic={true}>
                  <Wrapper>
                    <HeaderContainer>
                      <StyledHeader as="h1">{Strings.transactionFeeSettings.capHeading}</StyledHeader>
                      <StyledSwitch
                        className="tester"
                        toggle={true}
                        checked={data.getCapSettings[0].capStatus === 'ON' ? true : false}
                        onChange={() => this.toggleCaps(data.getCapSettings!![0].capStatus === 'ON' ? 'OFF' : 'ON')}
                      />
                    </HeaderContainer>
                    {data.getCapSettings[0].capStatus === 'ON' && (
                      <>
                        <SettingsGroup
                          disabled={data.getCapSettings[0].capStatus === 'ON' ? false : true}
                          data={IndividualData}
                          groupTitle={Strings.transactionFeeSettings.capGroups.individualUser}
                          image={Individual}
                        />
                        <SettingsGroup
                          disabled={data.getCapSettings[0].capStatus === 'ON' ? false : true}
                          data={TeamData}
                          groupTitle={Strings.transactionFeeSettings.capGroups.team}
                          image={Team}
                        />
                      </>
                    )}
                  </Wrapper>
                </SettingsSegment>
                <ExclusionSegment>
                  <ExclusionContainer>
                    <Header as="h2">{Strings.transactionFeeSettings.exclusionCap.heading}</Header>
                    <p>{Strings.transactionFeeSettings.exclusionCap.content}</p>
                    <SliderGroup
                      min={exclusionMin}
                      max={exclusionMax}
                      onChange={this.onChangeExclusionCap}
                      value={exclusionCap}
                      title={''}
                      disabled={data.getCapSettings[0].capStatus === 'ON' ? false : true}
                    />
                  </ExclusionContainer>
                </ExclusionSegment>
              </StyledSegmentGroup>
            )
          }
          return null
        }}
      </CapSettingQuery>
    )
  }
}

const mapStateToProps = (state: AppState) => {
  const { capSettings } = state.settings.transactionFee
  return {
    exclusionCap: capSettings.exclusionCap,
    exclusionMax: capSettings.exclusionMax,
    exclusionMin: capSettings.exclusionMin,
    max: capSettings.max,
    min: capSettings.min,
  }
}

export default connect(
  mapStateToProps,
  {
    changeExclusionCap: Actions.changeExclusionCap,
    changeCapStatus : Actions.updateCapToggle
  }
)(CapsSettings)
