// Import Packages
import * as React from 'react'

// Import Styled Components
import { UserCreditDebit, UserProfileCounters, UserProfileType } from 'store/UserProfile/Types'
import DetailSection from './DetailSection'
import GraphSection from './GraphSection'

// Import Styled Components
import {
  ProfileDetail
} from './Styled'

interface Props {
  openAccTransaction: () => void
  openPaymentInfo: () => void
  openPaymentHistory: () => void
  openChangePassword: () => void
  deleteUserCreditDebit: (data: string) => void
  getUserCreditDebit: (data: UserCreditDebit[]) => void
  updateUserCreditDebit: (data: UserCreditDebit) => void
  openReferralPopup: () => void
  openSalesGoalsPopup: () => void
  creditDebit: UserCreditDebit[]
  userProfile: UserProfileType
  showEditButton: string
  activeIndex: number
  userID: string
  userProfileCounter: UserProfileCounters
  capStatusFromSettings : boolean
}

export default class ProfileDetailComponent extends React.Component<Props> {
  public render() {
    const { userProfileCounter, userID, openReferralPopup, openPaymentInfo, openSalesGoalsPopup, openPaymentHistory, openChangePassword, openAccTransaction, userProfile, showEditButton, getUserCreditDebit, deleteUserCreditDebit, updateUserCreditDebit, creditDebit, activeIndex, capStatusFromSettings } = this.props
    return (
      <ProfileDetail width={13}>
        <GraphSection
          userProfileCounter={userProfileCounter}
          openSalesGoalsPopup={openSalesGoalsPopup}
          openPaymentInfo={openPaymentInfo}
          openPaymentHistory={openPaymentHistory}
          openChangePassword={openChangePassword}
          userProfile={userProfile}
          openReferralPopup={openReferralPopup}
          capStatusFromSettings={capStatusFromSettings} />
        <DetailSection
          userID={userID}
          openAccTransaction={openAccTransaction}
          userProfile={userProfile}
          showEditButton={showEditButton}
          getUserCreditDebit={getUserCreditDebit}
          deleteUserCreditDebit={deleteUserCreditDebit}
          updateUserCreditDebit={updateUserCreditDebit}
          creditDebit={creditDebit}
          activeIndex={activeIndex}
        />
      </ProfileDetail>
    )
  }
}
