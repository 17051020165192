// Import Packages
import { Grid, Input, Popup, Progress, Table } from 'semantic-ui-react'
import styled from 'styled-components'

// Import Colors
import Colors from 'design/Colors'
import { scrollbars } from 'design/Styled'

interface Props {
  background?: string
  border?: number
  borderRadius?: number
  width?: number
  height?: number
}

const Container = styled.div`
  padding: 40px 0px 0px 40px;
  color: ${Colors.Black500};
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 90vh;
`

const TeamName = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 50px;
  text-transform:capitalize;
`

const Office = styled.div`
  display: flex;
  font-size: 12px;
  margin-top: 25px;
  margin-bottom: 25px;
  text-transform:capitalize;
  align-items: center;

  span {
    width: 130px;
    line-height: 1.2;
    margin-left: 5px;
    text-align: left;
  }
`

const Lead = styled.div`
  display: flex;
  font-size: 12px;
  margin-bottom: 40px;
  text-transform:capitalize;
  text-transform:capitalize;
  align-items: center;

  span {
    width: 130px;
    line-height: 1.2;
    margin-left: 5px;
    text-align: left;
  }
`

const Text = styled.div`
  color: ${Colors.Black550};
  width: 85px;
`

const Title = styled.div`
  margin-top: 30px;
`

const TeamSection = styled.div`
  display: flex;
`

const Propfile = styled.div`
  overflow: auto;
  height: 275px;
  ${scrollbars};
`

const Before = styled.div`
  margin-top: 28px;

  i.icon {
    transform: rotate(-180deg);
    font-size: 22px;
    margin-top: 12px;
    margin-left: -3px;
    color: ${Colors.Black610};
    text-shadow: 3px -1px 5px rgba(0, 0, 0, 0.14);
  }
`

const ImageContainer = styled.div`
  display: flex;
  cursor: pointer;
  position: relative;
`

const StyledPopup = styled(Popup)`
  &.ui.popup {
    padding: 8px;
    padding-top: 8px;
    padding-bottom: 5px;
    font-size: 12px;
    color: ${Colors.Black500};
    text-transform: capitalize;
    cursor: pointer;
  }
`

const Images = styled.div`
  background: url(${(props: Props) => props.background || props.background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: ${(props: Props) => props.width || props.width}px;
  height: ${(props: Props) => props.height || props.height}px;
  align-self: center;
  margin-top: 20px;
  position: inherit;
  border: ${(props: Props) => props.border || props.border}px solid ${Colors.DarkBlue200};
  border-radius: ${(props: Props) => props.borderRadius || props.borderRadius}%;
`

const Delete = styled.div`
  i.icon {
    margin: 0px;
    font-size: 1.3em;
  }
`

const Details = styled.div`
  width: 530px;
  background: ${Colors.White910};
  margin-top: 14px;
  margin-left: -12px;
  position: relative;
  .ui.progress{
    margin: 15px 15px 0 !important;
  }
  .progressLessThan{
    .ui.progress{
      .bar>.progress{
        left:0;
      }
    }
  }
`

const Leader = styled.div`
  display: flex;
  font-size: 12px;
  margin-top: 15px;
  background: ${Colors.White1000};
  padding: 10px;
  color: ${Colors.Grey850};
`

const MemberName = styled.div`
  flex-grow: 1;
  text-transform:capitalize;
`

const LeaderName = styled.div`
  text-transform:capitalize;
`

const StyledTeamGrid = styled(Grid)`
  font-size: 12px;

  &.ui.grid {
    padding: 25px 0px 0px 35px;
    width: 550px;

  }

  .ui.table tr td {
    border-top: none;
  }
`

const Since = styled.div`
  font-size: 10px;
  font-style: italic;
  color: ${Colors.Black550};
`

const StyledProgress = styled(Progress)`
  height: 15px;
  border-radius: 0 !important;
  margin-top: 40px !important;
  margin-bottom: 0 !important;
  background: ${Colors.Black610} !important;

  &.ui.progress .bar {
    height: 15px;
    border-radius: 0;
    color: ${Colors.DarkBlue200};
    font-size: 11px;
    background: ${Colors.DarkBlue200}
  }

  &.ui.progress .bar>.progress {
    top: 52%;
  }
`
const StyledColumn = styled(Grid.Column)`
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;

  i.icon {
    font-size: 24px;
    margin-bottom: 12px;
  }
`

const Agreement = styled.div`
  font-size: 10px;
`


const FileName = styled.div`
  font-size: 12px;
  word-break: break-word;
`

const StyledCell = styled(Table.Cell)`
  font-size: 15px;

  .fa-dollar-sign {
    height: 13px;
    width: 10px;
    margin-right: 2px;
  }

  .fa-pencil-alt {
    margin-left: 15px;
    color: ${Colors.Black610};
    cursor: pointer;
  }
`

const StyledTable = styled(Table)`
  &.ui.basic.table{
    width: 260px;
    padding: 0px 0px 10px 15px;
    color: ${Colors.Black500};
  }

  &.ui.table td {
    padding: 4px;
  }
`

const EditBoardName = styled.div`
  margin-top: -5px;

  i.icon {
    font-size: 20px;
    margin-left: 10px;
  }

`

const StyledInput = styled(Input)`
  height: 24px;
  margin-top: 6px;
  width: 50px;
`

const Contribution = styled.div`
  text-align: center;
  font-size: 12px;
  margin-top: 5px;
  color: ${Colors.DarkBlue200};
  font-weight: bold;
  text-transform:capitalize;
`

const CreatedOn = styled.div`
  margin-top: 30px;
  text-align: end;
  font-size: 12px;
  margin-bottom: 10px;
  width: 615px;
  font-style: italic;

  span {
    color: ${Colors.Black550}
  }
`

const ProgressContainer = styled.div`
  height: 150px;
  width: 150px;
  margin-left: 20px;
  position: absolute;

  text {
    display: none;
  }
`

const Completed = styled.div`
    top: 50%;
    bottom: 0;
    right: 0;
    left: 0;
    position: absolute;
    padding: 0;
    text-align: center;
    width: 100%;
    transform: translateY(-40%);
`

const CompletedText = styled.div`
  font-size: 8px;
  font-weight: bold;
`

const Percentage = styled.div`
  font-size: 12px;
  font-weight: bold;
`

const TeamCAP = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 40px;
`

const TeamCAPTitle = styled.div`
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 8px;
  margin-left: 10px;
`

const ResidentialCAP = styled.div`
  font-size: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-weight: bold;

  .fa-circle {
    width: 12px;
    height: 13px;
    margin-right: 5px;
    color: ${Colors.DarkBlue200};
  }
`

const CommercialCAP = styled.div`
  font-size: 10px;
  display: flex;
  align-items: center;
  font-weight: bold;

  .fa-circle {
    width: 12px;
    height: 13px;
    margin-right: 5px;
    color: ${Colors.DarkBlue235};
  }
`

export {
  Agreement,
  Before,
  CommercialCAP,
  Completed,
  CompletedText,
  Container,
  Contribution,
  CreatedOn,
  Delete,
  Details,
  EditBoardName,
  FileName,
  ImageContainer,
  Images,
  Lead,
  Leader,
  LeaderName,
  MemberName,
  Office,
  Percentage,
  ProgressContainer,
  Propfile,
  ResidentialCAP,
  Since,
  StyledCell,
  StyledColumn,
  StyledPopup,
  StyledInput,
  StyledProgress,
  StyledTable,
  StyledTeamGrid,
  TeamCAP,
  TeamCAPTitle,
  TeamName,
  TeamSection,
  Text,
  Title,
}
