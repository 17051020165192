// Import Packages
import * as React from 'react'

// Import Components
import { UserProfileCounters, UserProfileType, UserType } from 'store/UserProfile/Types'
import Cards from './Cards'
import Data from './DummyData'

// Import Utils
import { getLoggedInUser } from 'utils'

// Import Styled Components
import {
  Container,
  GraphSectionContainer,
} from './Styled'

interface State {
  user: UserType
  sales: {
    contacts: number,
    totalLeads: number,
    transactions: number
  }
}

interface Props {
  openPaymentInfo: () => void
  openPaymentHistory: () => void
  openChangePassword: () => void
  openReferralPopup: () => void
  openSalesGoalsPopup: () => void
  userProfile: UserProfileType
  userProfileCounter: UserProfileCounters
  capStatusFromSettings?: boolean
}

export default class GraphSection extends React.Component<Props, State> {
  public state = {
    sales: {
      contacts: 0,
      totalLeads: 0,
      transactions: 0
    },
    user: {} as UserType
  }

  public async componentDidMount() {
    const user = await getLoggedInUser()
    this.setState({
      ...Data,
      user
    })
  }

  public render() {
    const { userProfile, openReferralPopup, openSalesGoalsPopup, userProfileCounter,capStatusFromSettings } = this.props
    return (
      <div>
        <Container>
          <GraphSectionContainer>
            <Cards userProfileCounter={userProfileCounter} userProfile={userProfile} openReferralPopup={openReferralPopup} openSalesGoalsPopup={openSalesGoalsPopup} capStatusFromSettings={capStatusFromSettings} />
          </GraphSectionContainer>
        </Container>
      </div>
    )
  }
}
