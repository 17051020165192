import gql from 'graphql-tag'

import {
  propertyFragment,
  publicPropertyFragment,
  referralFragment,
  transactionFragment,
  userFragment,
} from './Fragments'

import {
  documentFragment,
  documentMarkupFragment,
} from '../Document/Fragments'

export const GET_TRANSACTIONS = gql`
  query getTransactions(
    $officeId: String
    $searchData: SearchTransaction
    $contact: String
    $shareWith: String
    $userPassFilter: UserPassTransactionFilter
    $skip: Int
    $limit: Int
  ) {
    getTransactions(
      where: {
        isActive: true
        officeTransactionBoard: $officeId
        contact: $contact
        shareWith: $shareWith
      }
      filter: $searchData
      userPassFilter: $userPassFilter
      skip: $skip
      limit: $limit
    ) {
      ...TransactionFragment
    }
  }
  ${transactionFragment}
`

export const PUBLIC_GET_TRANSACTION = gql`
  query publicGetTransaction($_id: ID!) {
    publicGetTransaction(_id: $_id) {
      _id
      createdAt,
      owner {
        emails {
          type
          value
        }
        firstName
        lastName
        phones {
          smsEnabled
          type
          value
        }
        profileImage
      }
      propertyId {
        ...PublicPropertyFragment
      }
      status
      tenant {
        companyName
        companyLogo
      }
      transactionRole
      updatedAt,
    }
  }
  ${publicPropertyFragment}
`

export const GET_TRANSACTION_DATA_VIA_IDX = gql`
  query getTransactionDataViaIdx($input: TransactionIdxInput) {
    getTransactionDataViaIdx(input: $input) {
      propertyId {
        address {
          city
          state
          streetName
          streetNumber
          zipCode
        }
        bathrooms
        bedrooms
        description
        mlsId
        price
        squareFt
        subType {
          _id
          name
        }
        type {
          _id
          name
        }
      }
      transactionRole
    }
  }
`

export const GET_TRANSACTION_DETAILS = gql`
  query getTransactions($transactionId: String) {
    getTransactions(where: { _id: $transactionId }) {
      _id
      propertyId {
        ...PropertyFragment
      }
      transactionId
      transactionEmail
      transactionRole
      status
      tags
      progress
      createdAt
      updatedAt
      tenant {
        companyLogo
      }
      owner {
        ...UserFragment
        isPaid
        licensedExpiration
        paymentMethod
        transactionFeeSetting {
          _id
          name
        }
        userCap {
          residentialTargetCap
          commercialTargetCap
          residentialCompletedCap
          commercialCompletedCap
          residentialCapStatus
          commercialCapStatus
        }
      }
      shareWith {
        ...UserFragment
      }
      officeTransactionBoard {
        office {
          _id
        }
      }
    }
  }
  ${propertyFragment}
  ${userFragment}
`

export const GET_TRANSACTION_DOC_CHECKLIST = gql`
  query getTransactionDocChecklist($transactionId: String) {
    getDocTransactionCheckLists(
      where: { transaction: $transactionId, isActive: true }
    ) {
      _id
      name
      status
      description
      transaction {
        progress
      }
    }
  }
`

export const GET_TRANSACTIONS_DOCUMENTS = gql`
  query getTransactionDocuments($transactionId: String) {
    getDocuments(where: { transaction: $transactionId, isActive: true }) {
      ...DocumentFragment
      owner {
        _id
        firstName
        lastName
      }
      transaction {
        _id
        transactionId
        owner {
          _id
        }
      }
      viewerMarkup {
        ...DocumentMarkupFragment
      }
    }
  }
  ${documentMarkupFragment}
  ${documentFragment}
`

export const GET_TRANSACTION_WORKFLOW = gql`
  query getWorklfowCategory($categoryName: String) {
    getWorkflowCategories(where: { name: $categoryName, isActive: true }) {
      _id
      name
      isActive
      workflows {
        _id
        name
        visibility
        isActive
        workflowTasks {
          _id
          type
          name
          description
          dueDate
          isActive
        }
      }
    }
  }
`

export const GET_TRANSACTION_PROPERTY_IMAGE = gql`
  query getPropertyImages($propertyId: String) {
    getPropertyImages(where: { propertyId: $propertyId }) {
      _id
      name
      url
      order
    }
  }
`

export const GET_TRANSACTION_MANAGER_NOTES = gql`
  query getNotes($transactionId: String) {
    getNotes(where: { transaction: $transactionId, isActive: true }) {
      _id
      description
      createdBy {
        _id
        firstName
        profileImage
      }
      createdAt
    }
  }
`

export const GET_TRANSACTIONS_MESSAGES = gql`
  query getTransactionMessages($transactionId: String) {
    getMessages(where: { transaction: $transactionId }) {
      _id
      message
      createdBy {
        _id
        firstName
        lastName
        profileImage
      }
      createdAt
    }
  }
`

export const GET_TRANSACTION_COMMISSION = gql`
  query getTransactionCommission($transactionId: String) {
    getPayments(where: { transaction: $transactionId }) {
      _id
      fees {
        name
        amount
      }
      teamCap{
          residentialTargetCap
          commercialTargetCap
          residentialCompletedCap
          commercialCompletedCap
      }
      expirationDate
      transaction {
        _id
        status
        owner {
          _id
          firstName
          lastName
          userCap {
            residentialTargetCap
            commercialTargetCap
            residentialCompletedCap
            commercialCompletedCap
            residentialCapStatus
            commercialCapStatus
          }
          licensedExpiration
          transactionFeeSetting {
            _id
            name
          }
          paymentMethod
          hasTeam
        }
      }
      expectedCommission
      actualCommission
      totalCredits
      totalIncome
      totalExpenses
      totalDebits
      totalReferrals
      commissionDue
      paidDate
      receivedDate
      closingDate
      isApproved
      isReleased
      referrals {
        ...ReferralFragment
        payment {
          _id
        }
      }
      fees {
        _id
        type
        name
        amount
        key
        feeObj {
          _id
          type
          fee
        }
        isDefault
      }
    }
  }
  ${referralFragment}
`

export const GET_UNREAD_MESSAGE_COUNT = gql`
  query getUnreadMessageCount($transactionId: String, $userId: String) {
    getUnreadMessageCount(
      where: { transaction: $transactionId, readBy: $userId }
    )
  }
`

export const GET_CHECKLIST_LISTS = gql`
  query getDocChecklists {
    getDocCheckLists(where: { isActive: true }) {
      _id
      checkListCategory {
        _id
        name
      }
      mls {
        _id
        shortName
      }
      checkListSubType
      docTemplates {
        _id
        name
      }
    }
  }
`

export const GENERIC_GET_TRANSACTIONS = gql`
  query getTransactions(
    $where: TransactionFilter
    $sort: SortFilter
    $filter: SearchTransaction
  ) {
    genericGetTransactions: getTransactions(
      where: $where
      sort: $sort
      filter: $filter
    ) {
      _id
      payment {
        closingDate
        expectedCommission
        actualCommission
        commissionDue
      }
      propertyId {
        _id
        type {
          _id
          name
        }
        subType {
          _id
          name
        }
        address {
          _id
          type
          streetNumber
          streetName
          city
          state
          country
          zipCode
        }
        images {
          _id
          name
          url
          order
        }
        mlsId
        price
      }
      transactionId
      transactionEmail
      transactionRole
      status
      tags
      progress
      createdAt
      updatedAt
      owner {
        _id
        firstName
        lastName
        userName
        userCap {
          residentialTargetCap
          commercialTargetCap
          residentialCompletedCap
          commercialCompletedCap
          residentialCapStatus
          commercialCapStatus
        }
        licensedExpiration
        transactionFeeSetting {
          _id
          name
        }
        paymentMethod
      }
      shareWith {
        _id
        firstName
        lastName
        userName
        role
        status
        type
        phones {
          type
          value
        }
        emails {
          type
          value
        }
        profileImage
      }
      isActive
      createdAt
    }
  }
`

export const GET_COMMISSION_DISBURSEMENT = gql`
  query getCommissionDisbursement($transactionId: ID) {
    getCommissionDisbursement(transaction: $transactionId)
  }
`

export const GET_TRANSACTION_TOLERANCE_CHECKER = gql`
  query getTransactionToleranceChecker($transactionId: ID!) {
    getTransactionToleranceChecker(_id: $transactionId) {
      _id
      transaction {
        _id
        propertyId {
          _id
          address {
            _id
            streetNumber
            streetName
          }
        }
      }
      receivedDate
      actualCommission
    }
  }
`

export const GET_FLYER_CATEGORIES = gql`
  query getFlyerCategories($transactionId: ID) {
    getFlyerCategories(transactionId: $transactionId) {
      _id
      name
      flyerSubCategories {
        _id
        name
        flyerTemplates {
          _id
          title
          data
          type
          owner {
            _id
            firstName
          }
        }
      }
    }
  }
`
