// Import Packages
import { debounce, escapeRegExp, filter } from 'lodash'
import * as React from 'react'
import { Button, Form, Image, Input, Select } from 'semantic-ui-react'

// Import Images and Icons
import Modal from 'shared/Modal'

// Import Components
import LoadingIndicator from 'shared/LoadingIndicator'
import client from 'queries/apollo'
import { Options, TeamItemsDetails } from 'store/Teams/Types'
import { handleTeamCapValidation, handleValidation } from './Validation'

// Import Graphql Queries
import { GET_OFFICES } from 'queries/graphql/Offices/Queries'
import { GET_TEAM_USERS, GET_USERS } from 'queries/graphql/Users/Queries'
import { FormErrorType, FormType } from './Types'

// Import Utils
import { Strings } from 'utils'

// Import Styled Components
import { AddForm, AddLeftPanel, Container, StyledForm, StyledSearch } from './Styled'
import Toast from 'shared/Toast'

interface Props {
  closeModal: () => void
  addNewTeam: (newTeamData: FormType) => void
  boardId: string
  edit?: boolean
  teamCard?: TeamItemsDetails | any
  teamLeader?: string
}

interface SearchOptions {
  _id: string
  title: string
}

interface State {
  form: FormType
  userlist: SearchOptions[]
  officelist: Options[]
  isLoading: boolean
  value: string
  results: SearchOptions[]
  selectedOffice: Options
  leaderId: string
  animate: boolean
  loading: string
  submitBtnLoading: boolean
}

class AddTeamModal extends React.Component<Props, State> {
  public state = {
    animate: true,
    form: {
      errors: {} as FormErrorType,
      leaderName: '',
      office: {
        _id: '',
        branchName: ''
      },
      teamName: ''
    } as FormType,
    isLoading: false,
    leaderId: '',
    loading: '',
    submitBtnLoading:false,
    officelist: [],
    results: [],
    selectedOffice: {
      key: '',
      text: '',
      value: ''
    },
    userlist: [],
    value: ''
  }

  public async componentDidMount() {
    const { boardId, teamCard, edit, teamLeader } = this.props
    if(edit){
      this.setState({ loading: 'Gathering data...' })
    } else{
      this.setState({...this.state,loading:'Loading...'})
    }
    if (boardId === '') {
      const offices: any = await client.query({
        fetchPolicy: 'network-only',
        query: GET_OFFICES
      })
      const officelist: Options[] = []
      if (offices.data && offices.data.getOffices) {
        offices.data.getOffices.forEach((office: any) => {
          officelist.push({
            key: office._id,
            text: office.branchName,
            value: `${office.branchName}, ${office._id}`
          })
          if (teamCard && office.branchName === teamCard.branchName) {
            this.setState({
              selectedOffice: {
                key: office._id,
                text: office.branchName,
                value: `${office.branchName}, ${office._id}`
              }
            })
          }
        })
      }
      this.setState({ officelist })
    }

    let officeId = boardId
    if (teamCard && Object.keys(teamCard).length > 0 && teamCard.office._id) {
      officeId = teamCard.office._id
    }

    try{
      const usersdata: any = await client.query({ query: GET_USERS })
    if (usersdata.data && usersdata.data.getUsers) {
      usersdata.data.getUsers.forEach((element: any) => {
        if ((teamCard && element.userName === teamCard.userName) || (teamLeader && element.userName === teamLeader)) {
          this.setState({ value: `${element.firstName} ${element.lastName}`, leaderId: element._id })
        }
      })
    }

    if (officeId !== '') {
      const users = await client.query({
        fetchPolicy: 'network-only',
        query: GET_TEAM_USERS,
        variables: { officeId: officeId, hasTeam: edit ? true : false }
      })
      const { data }: any = users
      const userlist: SearchOptions[] = []
      if (data && data.getUsers) {
        data.getUsers.forEach((user: any) => {
          if (user.role !== 'MANAGER') {
            userlist.push({
              _id: user._id,
              title: `${user.firstName} ${user.lastName}`
            })
            this.setState({ userlist })
          }
        })
      }
    }

    if (edit) {
      const { form } = this.state
      this.setState({ value: `${teamCard.firstName} ${teamCard.lastName}` })
      form.leaderName = `${teamCard.firstName} ${teamCard.lastName}`
      form.teamName = `${teamCard.teamName}`
      form.errors = {} as FormErrorType
      this.setState({ form })
      this.setState({
        form: {
          ...teamCard,
          errors: {} as FormErrorType
        },
        loading: ''
      })
    }
    } catch(error){
      Toast({
        message: 'Something went wrong in fetching users',
        type: 'error'
      })
      this.closeModal()
    } finally{
      this.setState({...this.state,loading:''})
    }
  }

  public render() {
    const { boardId, teamLeader, edit } = this.props
    const {
      animate,
      selectedOffice,
      officelist,
      isLoading,
      results,
      loading,
      value,
      form,
      form: { errors },
      submitBtnLoading
    } = this.state
    return (
      <Modal
        content={
          <Container>
            {loading && <LoadingIndicator message={loading} />}
            <AddLeftPanel>
              {edit ? <Image src={Strings.teams.src1} size="small" /> : <Image src={Strings.teams.src} size="small" />}
            </AddLeftPanel>
            {
                !loading && 
            <AddForm>
                <StyledForm size={'mini'}>
                <Form.Field
                  control={Input}
                  label="Team Name"
                  name="teamName"
                  value={form.teamName}
                  error={!!errors.teamName}
                  placeholder="Team Name"
                  onChange={this.handleChange}
                  disabled={submitBtnLoading}
                />
                {
                  edit && <div>
                    <Form.Field
                      control={Input}
                      label="Residential CAP"
                      type="number"
                      step=".01"
                      icon="dollar sign"
                      iconPosition="left"
                      placeholder="Residential CAP"
                      name="residentialTargetCap"
                      value={form.teamCap && form.teamCap.residentialTargetCap}
                      error={!!errors.teamCapResidential}
                      onChange={this.handleChange}
                      disabled={submitBtnLoading}
                    />
                    <Form.Field
                      control={Input}
                      label="Commercial CAP"
                      type="number"
                      step=".01"
                      icon="dollar sign"
                      iconPosition="left"
                      placeholder="Commercial CAP"
                      name="commercialTargetCap"
                      value={form.teamCap && form.teamCap.commercialTargetCap}
                      error={!!errors.teamCapCommercial}
                      onChange={this.handleChange}
                      disabled={submitBtnLoading}
                    />
                  </div>
                }
                {boardId === '' && (
                  <Form.Field
                    control={Select}
                    label="Office"
                    name="office"
                    placeholder="office"
                    options={officelist}
                    value={selectedOffice.value}
                    error={!!errors.office}
                    onChange={this.handleChange}
                    disabled={submitBtnLoading}
                  />
                )}
                <Form.Field error={!!errors.leaderName}>
                  <label>{Strings.teams.teamLeaders}</label>
                  <StyledSearch
                    loading={isLoading}
                    onResultSelect={this.handleResultSelect}
                    onSearchChange={debounce(this.handleSearchChange, 500, { leading: true })}
                    name="leaderName"
                    placeholder="Leader Name"
                    results={results}
                    value={value}
                    disabled={!selectedOffice.value || submitBtnLoading || teamLeader ? true : false}
                    {...this.props}
                  />
                </Form.Field>
                <Button disabled={submitBtnLoading} loading={submitBtnLoading} onClick={this.teamSubmit} content={'SAVE'} />
              </StyledForm>           
            </AddForm>
        }
          </Container>
        }
        className={animate ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeModal}
        width={600}
      />
    )
  }

  private handleResultSelect = (e: React.SyntheticEvent<HTMLDivElement>, { name, result }: any) => {
    const { form } = this.state
    form[name] = result._id
    this.setState({
      form,
      leaderId: result._id,
      value: result.title
    })
  }

  private handleSearchChange = (e: React.SyntheticEvent<HTMLDivElement>, { value }: any) => {
    this.setState({ isLoading: true, value })
    if (value === '') {
      this.setState({ leaderId: '' })
    }
    setTimeout(() => {
      if (this.state.value.length < 1) {
        return this.resetComponent()
      }
      const re = new RegExp(escapeRegExp(this.state.value), 'i')
      const isMatch = (result: any) => re.test(result.title)
      this.setState({
        isLoading: false,
        results: filter(this.state.userlist, isMatch)
      })
    }, 300)
  }

  private resetComponent = () => this.setState({ isLoading: false, results: [], value: '' })

  private closeModal = () => {
    const { closeModal } = this.props
    this.setState({ animate: false })
    window.setTimeout(() => {
      closeModal()
    }, 300)
  }

  private handleChange = async (e: React.SyntheticEvent<HTMLDivElement>, { name, value }: any) => {
    const { form } = this.state
    const { edit } = this.props
    if (name === 'office') {
      const res = value.split(', ')
      const users = await client.query({
        fetchPolicy: 'network-only',
        query: GET_TEAM_USERS,
        variables: { office: res[1], hasTeam: edit ? true : false }
      })
      form.office._id = res[1]
      form.office.branchName = res[0]
      this.setState({
        selectedOffice: {
          key: res[1],
          text: res[0],
          value: `${res[0]}, ${res[1]}`
        }
      })
      const { data }: any = users
      const userlist: SearchOptions[] = []
      if (data && data.getUsers) {
        data.getUsers.forEach((user: any) => {
          if (user.office !== null && user.office.some((el : any) => el._id === res[1])) {
            userlist.push({
              _id: user._id,
              title: `${user.firstName} ${user.lastName}`
            })
          }
          this.setState({ userlist })
        })
      }
    } else if (name === 'residentialTargetCap') {
      form.teamCap.residentialTargetCap = parseFloat(value)
    } else if (name === 'commercialTargetCap') {
      form.teamCap.commercialTargetCap = parseFloat(value)
    } else {
      form[name] = value
    }
    this.setState({ form })
  }

  private addNewCard = () => {
    const { addNewTeam } = this.props
    const { form } = this.state
    try{
      this.setState({...this.state,submitBtnLoading:true})
     addNewTeam(form)
    } catch(error){
      Toast({
        message: 'Creating New Team Failed',
        type: 'error'
      })
    } finally{
      this.setState({...this.state,submitBtnLoading:false})
    }
    
  }

  private teamSubmit = () => {
    const { edit } = this.props
    const { form, leaderId } = this.state
    const name = 'leaderName'
    form[name] = leaderId

    if(edit) {
      const validation = handleTeamCapValidation(form)
      if (!validation.formIsValid) {
        form.errors = validation.errors
        this.setState({ form })
        return
      }
      this.addNewCard()
    } else {
      const validation = handleValidation(form)
      if (!validation.formIsValid) {
        form.errors = validation.errors
        this.setState({ form })
        return
      }
      this.addNewCard()
    }
    this.closeModal()
  }
}

export default AddTeamModal
