import * as Cookies from 'js-cookie'

let timer:any
const INACTIVITY_LOGOUT_TIME=60*60*1000
export const logoutTimer = () => {
  if(timer){
    clearTimeout(timer)
  }
  timer=setTimeout(()=>{
    Cookies.remove('connect.sid')
    Cookies.remove('connect.sid.sig')
    localStorage.clear()
     window.location.href='/login'
  },INACTIVITY_LOGOUT_TIME)
}
